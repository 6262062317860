<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar
            color="#003d6a"
            dark
            dense
            style="z-index: 9"
            elevation="0"
            @click="searchBidCollapsed = !searchBidCollapsed"
          >
            <v-toolbar-title>{{ $t("new_reservation") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="card-header-actions">
              <span v-if="searchBidCollapsed">
                <font-awesome-icon :icon="['fas', 'chevron-up']" size="lg"
              /></span>
              <span v-else
                >{{ $t("search") }}
                <font-awesome-icon
                  :icon="['fas', 'chevron-down']"
                  style="margin-left: 5px"
                  size="lg"
                />
              </span>
            </div>
          </v-toolbar>
          <CCollapse :show="searchBidCollapsed && !isManually">
            <CForm @submit.prevent="searchBid">
              <v-row class="mt-3">
                <v-col :md="isPartner ? '6' : '5'">
                  <v-autocomplete
                    dense
                    hide-details
                    outlined
                    clearable
                    v-model="searchBidSelectedDeliveryLocationSlug"
                    :items="searchBidSelectLocationOptions"
                    item-text="label"
                    item-value="value"
                    :placeholder="$t('pickup_location')"
                    :label="$t('pickup_location')"
                    :disabled="searchBidInputDisabled"
                  />
                </v-col>

                <v-col :md="isPartner ? '6' : '4'">
                  <VueCtkDateTimePicker
                    id="pickup_datetime"
                    no-shortcuts
                    no-header
                    format="YYYY-MM-DD HH:mm"
                    formatted="Do MMM YYYY ddd HH:mm"
                    :minDate="deliveryMinDateTime"
                    :minuteInterval="offsetMinute"
                    v-model="searchBidForm.delivery.datetime"
                    :locale="locale"
                    :label="$t('pickup_time')"
                  />
                </v-col>
                <v-col v-if="!isPartner" md="3">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    :label="$t('promo_code')"
                    type="text"
                    @input="promo_code = promo_code.turkishToUpper()"
                    v-model="promo_code"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :md="isPartner ? '6' : '5'">
                  <v-autocomplete
                    dense
                    hide-details
                    outlined
                    clearable
                    v-model="searchBidSelectedReturnLocationSlug"
                    :items="searchBidSelectLocationOptions"
                    item-text="label"
                    item-value="value"
                    :placeholder="$t('return_location')"
                    :label="$t('return_location')"
                    :disabled="searchBidInputDisabled"
                  />
                </v-col>

                <v-col cols="12" :md="isPartner ? '6' : '4'">
                  <VueCtkDateTimePicker
                    id="return_datetime"
                    no-shortcuts
                    no-header
                    format="YYYY-MM-DD HH:mm"
                    formatted="Do MMM YYYY ddd HH:mm"
                    :minDate="deliveryMinDateTime"
                    :minuteInterval="offsetMinute"
                    v-model="searchBidForm.return.datetime"
                    :locale="locale"
                    :label="$t('return_time')"
                  />
                </v-col>

                <v-col
                  v-if="this.$store.state.GetServerData.userType === 'admin'"
                >
                  <RoleProvider slug="PARTNER_RESERVATION_CREATE">
                    <template slot-scope="{ canItPass }">
                      <v-autocomplete
                        v-if="canItPass"
                        dense
                        hide-details
                        outlined
                        clearable
                        v-model="selectedPartnerInfo"
                        :items="partnerInfo"
                        item-text="selectedPartnerValue.title"
                        item-value="selectedPartnerValue"
                        :placeholder="$t('reservationOfPartner')"
                        :label="$t('reservationOfPartner')"
                        :disabled="searchBidInputDisabled"
                      />
                    </template>
                  </RoleProvider>
                </v-col>

                <RoleProvider
                  v-if="!isPartner"
                  slug="MANUEL_RESERVATION_CREATE"
                >
                  <template slot-scope="{ canItPass }">
                    <v-col cols="12" md="6">
                      <v-checkbox
                        hide-details
                        dense
                        v-model="isManually"
                        label="Manuel Olarak Rezervasyon Yap"
                      >
                      </v-checkbox>
                    </v-col>
                  </template>
                </RoleProvider>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    :disabled="searchBidInputDisabled"
                    type="submit"
                    block
                    color="primary"
                  >
                    <strong>
                      {{ $t("search") }}
                    </strong>
                    <v-icon right dark> mdi-magnify </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </CForm>
          </CCollapse>
          <RoleProvider v-if="isPartner && bidDetailGridShow">
            <template slot-scope="{ canItPass }">
              <v-col cols="12" md="3">
                <v-autocomplete
                  dense
                  hide-details
                  outlined
                  clearable
                  multiple
                  :items="resTypeItems"
                  item-text="tr"
                  item-value="val"
                  v-model="resTypeFilter"
                  :label="$t('reservation_type')"
                >
                </v-autocomplete>
              </v-col>
            </template>
          </RoleProvider>

          <div v-if="selectableCampaigns?.length > 0" class="mx-5 my-2">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="my-0"
                v-for="campaign in selectableCampaigns"
                :key="campaign._id"
              >
                <v-alert
                  text
                  outlined
                  dense
                  class="rounded-lg pa-0"
                  :icon="false"
                  elevation="0"
                >
                  <v-row class="d-flex align-center">
                    <div
                      class="mx-4 font-weight-bold"
                      style="
                        color: #031633;
                        border-style: dashed;
                        border: #e2211c dashed 3px;
                        border-radius: 7px;
                        padding: 0px 4px;
                      "
                    >
                      {{ campaign.promoCode }}
                    </div>
                    <div>
                      <div
                        class="font-weight-bold grey--text text--darken-4 text-body-1"
                      >
                        {{ campaign.label[$i18n.locale] }}
                      </div>
                      <div class="grey--text text--darken-4 mt-n1 text-body-2">
                        {{ campaign.info?.name[$i18n.locale] }}
                      </div>
                    </div>
                    <v-col class="text-right">
                      <v-btn
                        elevation="0"
                        :ripple="false"
                        small
                        v-if="promo_code !== campaign.promoCode"
                        @click="
                          promo_code = campaign.promoCode;
                          searchBid();
                        "
                        color="success"
                      >
                        Kampanya Uygula
                      </v-btn>
                      <v-btn
                        v-if="promo_code === campaign.promoCode"
                        elevation="0"
                        class="white--text"
                        :ripple="false"
                        medium
                        small
                        @click="
                          promo_code = '';
                          searchBid();
                        "
                        color="red"
                        >Kampanyadan Çık
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </div>

          <v-row class="p-0" v-if="bidDetailGridShow">
            <v-btn @click="pdfExport" v-if="1 == 2">PDF Export</v-btn>
            <ag-grid-vue
              style="width: 100%; height: calc(80vh)"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :enableBrowserTooltips="true"
              :enableRowGroup="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
              @column-moved="onColumnMoved"
              @column-pinned="onColumnMoved"
              @column-visible="onColumnMoved"
              @column-resized="onColumnMoved"
              @column-changed="onColumnMoved"
              @column-row-group-changed="onColumnMoved"
            ></ag-grid-vue>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      style="z-index: 1031 !important"
      v-model="newReservationModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark dense elevation="0">
          <v-spacer></v-spacer>

          <v-btn
            icon
            dark
            @click="(newReservationModal = false), (additionalExtrasItems = [])"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row>
          <v-col cols="12" lg="5" md="6" sm="12">
            <v-card elevation="10" class="mb-5">
              <v-toolbar dense dark color="grey darken-1" elevation="0">
                <v-toolbar-title
                  ><strong>
                    {{ $t("general") }}
                  </strong></v-toolbar-title
                >
                <v-spacer></v-spacer>
                {{ newReservationModalForm.searchPrice.car.brand }}
                {{ newReservationModalForm.searchPrice.car.model }}
              </v-toolbar>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="align-items-center justify-start"
                >
                  <v-img
                    height="70"
                    contain
                    :src="
                      ENV_URL_CARS +
                      newReservationModalForm.searchPrice.car.image
                    "
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="p-0 d-flex align-items-center"
                >
                  <v-img
                    :src="
                      ENV_URL_VENDOR +
                      newReservationModalForm.searchPrice.vendor.logo
                    "
                    max-width="150"
                  />
                </v-col>

                <v-col sm="12" md="12" class="ml-5">
                  <v-row class="align-items-center justify-content-center">
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong> {{ $t("class") }} </strong>
                      <p>
                        {{
                          $store.state.ceviri[
                            newReservationModalForm.searchPrice.car.class
                          ]
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>{{ $t("gear") }}</strong>
                      <p>
                        {{
                          $store.state.ceviri[
                            newReservationModalForm.searchPrice.car.transmission
                          ]
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>{{ $t("fuel") }}</strong>
                      <p>
                        {{
                          $store.state.ceviri[
                            newReservationModalForm.searchPrice.car.fuel
                          ]
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>{{ $t("seat") }}</strong>
                      <p>
                        {{ newReservationModalForm.searchPrice.car.seat }}
                      </p>
                    </v-col>

                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>{{ $t("km_limit") }}</strong>
                      <p>
                        {{
                          newReservationModalForm.searchPrice.rules
                            .dailyRangeLimit === 0 &&
                          !newReservationModalForm.searchPrice.isManuel
                            ? $t("unlimited")
                            : newReservationModalForm.searchPrice.rules
                                .totalRangeLimit + " KM"
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>
                        {{ $t("age") }}
                      </strong>
                      <p>
                        +
                        {{
                          newReservationModalForm.searchPrice.rules.driverAge
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>{{ $t("driving_license") }}</strong>
                      <p>
                        Min.
                        {{
                          newReservationModalForm.searchPrice.rules.licenseYears
                        }}
                        {{ $t("yearly") }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>
                        {{ $t("fuel_policy") }}
                      </strong>
                      <p>
                        {{
                          $store.state.ceviri[
                            newReservationModalForm.searchPrice.car.fuelPolicy
                          ]
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>
                        {{ $t("free_cancellation") }}
                      </strong>
                      <p>
                        {{
                          newReservationModalForm.searchPrice.vendor
                            ?.freeCancelInfo?.minHoursInfo
                            ? $t("free_cancel_text", {
                                minHoursInfo:
                                  newReservationModalForm.searchPrice?.vendor
                                    ?.freeCancelInfo?.minHoursInfo,
                              })
                            : $t("available")
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>
                        {{ $t("early_return_1") }}
                      </strong>
                      <p>
                        {{
                          newReservationModalForm.searchPrice.vendor
                            ?.noEarlyReturn
                            ? $t("none")
                            : $t("available")
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0">
                      <strong>
                        {{ $t("noShowCancelled") }}
                      </strong>
                      <p>
                        {{
                          newReservationModalForm.searchPrice.vendor
                            ?.noNoShowReturn
                            ? $t("none")
                            : $t("available")
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" class="p-0"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="m-0"></v-divider>
              <v-row>
                <v-col col="10">
                  <v-row>
                    <v-col col="4">
                      <h6>
                        {{ $t("pickup") }}
                      </h6>
                      <h5 style="font-weight: 900">
                        {{ newReservationModalForm.location.start.name }}
                      </h5>
                      <div class="d-flex align-items-center mt-3">
                        <font-awesome-icon
                          :icon="['fas', 'calendar-alt']"
                          size="lg"
                          class="mr-2"
                        />
                        <p class="h6 m-0">
                          {{
                            newReservationModalForm.datetime.start
                              | moment("Do MMMM YYYY, dddd")
                          }}
                          {{
                            newReservationModalForm.datetime.start
                              | moment("HH:mm")
                          }}
                        </p>
                      </div>

                      <div class="d-flex align-items-center mt-3">
                        <font-awesome-icon
                          :icon="['fas', 'info-circle']"
                          size="lg"
                          class="mr-2"
                        />
                        <p class="h6 m-0">
                          {{
                            $store.state.ceviri[
                              newReservationModalForm.searchPrice.office
                                .deliveryType
                            ]
                          }}
                        </p>
                      </div>
                      <p class="m-0 mt-1 small">
                        {{ newReservationModalForm.searchPrice.office.address }}
                      </p>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col col="6">
                      <h6>
                        {{ $t("return") }}
                      </h6>
                      <h5 style="font-weight: 900">
                        {{ newReservationModalForm.location.end.name }}
                      </h5>
                      <div class="d-flex align-items-center mt-3">
                        <font-awesome-icon
                          :icon="['fas', 'calendar-alt']"
                          size="lg"
                          class="mr-2"
                        />
                        <p class="h6 m-0">
                          {{
                            newReservationModalForm.datetime.end
                              | moment("Do MMMM YYYY, dddd")
                          }}
                          {{
                            newReservationModalForm.datetime.end
                              | moment("HH:mm")
                          }}
                        </p>
                      </div>
                      <div class="d-flex align-items-center mt-3">
                        <font-awesome-icon
                          :icon="['fas', 'info-circle']"
                          size="lg"
                          class="mr-2"
                        />
                        <p class="h6 m-0">
                          {{
                            $store.state.ceviri[
                              newReservationModalForm.searchPrice.returnOffice
                                .deliveryType
                            ]
                          }}
                        </p>
                      </div>
                      <p class="m-0 mt-1 small">
                        {{
                          newReservationModalForm.searchPrice.returnOffice
                            .address
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="m-0"></v-divider>

              <v-row
                v-if="!extrasLoading && additionalExtrasItems?.length == 0"
              >
                <v-col cols="12">
                  <p class="text-center">
                    {{ $t("no_additional_products_found") }}
                  </p>
                </v-col>
              </v-row>

              <v-row
                v-if="!extrasLoading && additionalExtrasItems?.length > 0"
                class="p-1"
              >
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  v-for="(extra, i) in additionalExtrasItems"
                  :key="i"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <p class="m-0">
                        <strong>{{ extra.name }}</strong>
                      </p>
                      <p class="m-0">
                        <strong
                          >{{
                            extra.totalPrice[
                              $store.state.currency
                            ].toLocaleString("tr-TR", {
                              style: "currency",
                              currency: $store.state.currency,
                            }) || null
                          }}
                        </strong>
                        <span v-if="extra.extradaily == 1">
                          ({{
                            extra.dailyPrice[
                              $store.state.currency
                            ].toLocaleString("tr-TR", {
                              style: "currency",
                              currency: $store.state.currency,
                            }) || null
                          }}
                          x
                          {{
                            `${(
                              extra.totalPrice[$store.state.currency] /
                              extra.dailyPrice[$store.state.currency]
                            ).toFixed(0)}`
                          }}
                          {{ $t("day") }})
                        </span>
                        <span v-else>
                          {{ $t("per_rental") }}
                        </span>
                      </p>
                    </div>

                    <div>
                      <v-btn
                        v-if="
                          calculatedPrices.selectedExtras
                            ?.map((e) => e.code)
                            .indexOf(extra.code) > -1
                        "
                        @click="
                          setExtra(
                            extra.name,
                            extra.dailyPrice,
                            extra.totalPrice,
                            0,
                            extra.vendor,
                            extra.code
                          )
                        "
                      >
                        <font-awesome-icon :icon="['fas', 'minus']" />
                      </v-btn>
                      <v-btn
                        v-if="
                          !calculatedPrices.selectedExtras ||
                          calculatedPrices.selectedExtras
                            .map((e) => e.code)
                            .indexOf(extra.code) == -1
                        "
                        @click="
                          setExtra(
                            extra.name,
                            extra.dailyPrice,
                            extra.totalPrice,
                            1,
                            extra.vendor,
                            extra.code
                          )
                        "
                      >
                        <font-awesome-icon :icon="['fas', 'plus']" />
                      </v-btn>
                    </div>
                  </div>

                  <div style="color: gray">
                    <small>{{ extra.description }}</small>
                  </div>
                  <v-divider class="mb-0"></v-divider>
                </v-col>
              </v-row>
              <v-row v-if="extrasLoading">
                <v-col cols="12">
                  <p class="text-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="20"
                    ></v-progress-circular>
                    {{ $t("loading") }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" lg="7" md="6" sm="12" class="ma-0 pa-0">
            <v-row>
              <v-col cols="12" lg="7" md="12" sm="12">
                <!-- ÖDEME BİLGİLERİ BAŞLANGIÇ -->
                <v-card elevation="10">
                  <v-toolbar dense dark color="grey darken-1" elevation="0">
                    <v-toolbar-title
                      ><strong>
                        {{ $t("driver_info") }}
                      </strong></v-toolbar-title
                    >
                  </v-toolbar>
                  <div>
                    <v-row>
                      <v-col cols="12">
                        <div class="select-user-box noselect">
                          <CInput
                            class="content-fix m-0"
                            v-model="usersModalForm.searchValue"
                            v-if="
                              Object.keys(usersModalSelectedUser).length === 0
                            "
                            :placeholder="$t('search_for_registered_driver')"
                          >
                            <template #append-content>
                              <button
                                type="button"
                                class="input-group-btn-right btn-bg-white"
                                @click="searchUser()"
                              >
                                {{ $t("search_user") }}
                              </button>
                            </template>
                          </CInput>
                          <div
                            class="selected-user"
                            v-if="
                              Object.keys(usersModalSelectedUser).length > 0
                            "
                          >
                            <label
                              class="m-0"
                              v-html="
                                usersModalSelectedUser.name +
                                ' ' +
                                usersModalSelectedUser.lastname
                              "
                            />

                            <div class="btn-right" @click="unselectUser()">
                              {{ $t("remove") }}
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <validation-observer ref="observer">
                      <v-row>
                        <v-col cols="6">
                          <validation-provider
                            mode="eager"
                            :name="$t('name')"
                            rules="required|alpha_spaces|max:500"
                          >
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              :label="$t('name')"
                              class="font-weight-bold"
                              @input="
                                newReservationModalForm.driverInfo.name =
                                  newReservationModalForm.driverInfo.name.turkishToUpper()
                              "
                              required
                              v-model="newReservationModalForm.driverInfo.name"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col col="6">
                          <validation-provider
                            mode="eager"
                            :name="$t('lastname')"
                            rules="required|alpha_spaces|max:500"
                          >
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              :label="$t('lastname')"
                              class="font-weight-bold"
                              required
                              @input="
                                newReservationModalForm.driverInfo.lastname =
                                  newReservationModalForm.driverInfo.lastname.turkishToUpper()
                              "
                              v-model="
                                newReservationModalForm.driverInfo.lastname
                              "
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            mode="eager"
                            :name="$t('mobile_phone')"
                            :rules="{
                              required: true,
                            }"
                          >
                            <vue-tel-input
                              @input="onInput"
                              :inputOptions="{
                                placeholder: $t('mobile_phone'),
                              }"
                              defaultCountry="tr"
                              mode="international"
                              v-model="
                                newReservationModalForm.driverInfo.phoneInput
                              "
                            ></vue-tel-input>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            :name="$t('email')"
                            mode="eager"
                            rules="required|email"
                          >
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              :label="$t('email')"
                              class="font-weight-bold"
                              v-model="newReservationModalForm.driverInfo.email"
                            />
                          </validation-provider>
                        </v-col>

                        <v-col cols="6">
                          <label
                            class="font-weight-bold"
                            v-html="$t('date_of_birth')"
                          />

                          <imask-input
                            class="form-control"
                            v-model="
                              newReservationModalForm.driverInfo.birthday
                            "
                            :mask="Date"
                            :lazy="false"
                            radix="."
                            :unmask="false"
                            :placeholder="$t('date_of_birth')"
                          />
                        </v-col>

                        <v-col cols="6">
                          <CInputRadioGroup
                            class="mb-2 font-weight-bold cursor-pointer noselect"
                            :options="[
                              { label: $t('tc_identity'), value: 'tc' },
                              { label: $t('passport'), value: 'passport' },
                            ]"
                            :checked="
                              newReservationModalForm.driverInfo.identity
                                .certificateType
                            "
                            :inline="true"
                            @update:checked="driverIdentityChanged"
                          />
                          <validation-provider
                            mode="eager"
                            :name="
                              newReservationModalForm.driverInfo.identity
                                .certificateType == 'tc'
                                ? $t('tc_identity')
                                : $t('passport')
                            "
                            :rules="
                              newReservationModalForm.driverInfo.identity
                                .certificateType == 'tc'
                                ? 'required|length:11'
                                : 'required|min:5'
                            "
                          >
                            <imask-input
                              class="form-control"
                              v-model="
                                newReservationModalForm.driverInfo.identity
                                  .value
                              "
                              :mask="
                                newReservationModalForm.driverInfo.identity
                                  .certificateType == 'tc'
                                  ? /^\d{0,11}$/
                                  : ''
                              "
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-divider class="m-0"></v-divider>
                      <v-row v-if="!isPartner">
                        <v-col cols="12" lg="12">
                          <h5 class="color-red">
                            {{ $t("billing_information") }}
                          </h5>
                        </v-col>
                      </v-row>

                      <v-row v-if="!isPartner">
                        <v-col class="bil__type" cols="12" lg="12">
                          <v-select
                            outlined
                            dense
                            hide-details
                            :label="$t('billing_type')"
                            v-model="
                              newReservationModalForm.invoices.invoiceType
                            "
                            :items="bilType"
                            item-text="label"
                            item-value="value"
                          />
                        </v-col>

                        <v-row
                          v-if="
                            newReservationModalForm.invoices.invoiceType ==
                            'individual'
                          "
                        >
                          <v-col
                            v-if="!dataDropDown.cities.length"
                            cols="12"
                            md="6"
                            sm="12"
                          >
                            <validation-provider
                              mode="eager"
                              :name="$t('province')"
                              rules="required|alpha_spaces|max:500"
                            >
                              <v-text-field
                                outlined
                                dense
                                hide-details
                                :label="$t('province')"
                                class="font-weight-bold"
                                v-model="
                                  newReservationModalForm.invoices.individual
                                    .province
                                "
                              />
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" md="6">
                            <validation-provider
                              mode="eager"
                              :name="$t('post_code')"
                              rules="required"
                            >
                              <v-text-field
                                outlined
                                dense
                                hide-details
                                :label="$t('post_code')"
                                class="font-weight-bold"
                                v-model="
                                  newReservationModalForm.invoices.individual
                                    .postCode
                                "
                              />
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" lg="12">
                            <validation-provider
                              mode="eager"
                              :name="$t('address')"
                              rules="required|max:500"
                            >
                              <v-text-field
                                outlined
                                dense
                                hide-details
                                :label="$t('address')"
                                class="font-weight-bold"
                                v-model="
                                  newReservationModalForm.invoices.individual
                                    .address
                                "
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-col
                          v-else-if="
                            newReservationModalForm.invoices.invoiceType ==
                            'individualCompany'
                          "
                          cols="12"
                          lg="12"
                        >
                          <v-row>
                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('name')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('name')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices
                                      .individualCompany.firstname
                                  "
                                />
                              </validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('lastname')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('lastname')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices
                                      .individualCompany.lastname
                                  "
                              /></validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('tax_office')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('tax_office')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices
                                      .individualCompany.taxOffice
                                  "
                                />
                              </validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('tax_tc')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('tax_tc')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices
                                      .individualCompany.taxNumber
                                  "
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('province')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('province')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices
                                      .individualCompany.province
                                  "
                              /></validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('post_code')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('post_code')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices
                                      .individualCompany.postCode
                                  "
                              /></validation-provider>
                            </v-col>
                            <v-col cols="12" lg="12">
                              <validation-provider
                                mode="eager"
                                :name="$t('address')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('address')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices
                                      .individualCompany.address
                                  "
                              /></validation-provider>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          v-else-if="
                            newReservationModalForm.invoices.invoiceType ==
                            'company'
                          "
                          cols="12"
                          lg="12"
                        >
                          <v-row>
                            <v-col cols="12">
                              <validation-provider
                                mode="eager"
                                :name="$t('company_title')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('company_title')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.company
                                      .title
                                  "
                              /></validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('tax_office')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('tax_office')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.company
                                      .taxOffice
                                  "
                              /></validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('tax_number')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  min="10"
                                  max="11"
                                  v-mask="'###########'"
                                  :label="$t('tax_number')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.company
                                      .taxNumber
                                  "
                              /></validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('province')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('province')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.company
                                      .province
                                  "
                              /></validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('post_code')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('post_code')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.company
                                      .postCode
                                  "
                              /></validation-provider>
                            </v-col>
                            <v-col cols="12" lg="12">
                              <validation-provider
                                mode="eager"
                                :name="$t('address')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('address')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.company
                                      .address
                                  "
                              /></validation-provider>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          v-else-if="
                            newReservationModalForm.invoices.invoiceType ==
                            'abroad'
                          "
                          cols="12"
                          lg="12"
                        >
                          <v-row>
                            <v-col cols="12">
                              <validation-provider
                                mode="eager"
                                :name="$t('company_title')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('company_title')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.abroad
                                      .title
                                  "
                              /></validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('tax_office')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('tax_office')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.abroad
                                      .taxOffice
                                  "
                              /></validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('tax_number')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('tax_number')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.abroad
                                      .taxNumber
                                  "
                              /></validation-provider>
                            </v-col>
                            <v-col>
                              <div>
                                <v-autocomplete
                                  outlined
                                  dense
                                  hide-details
                                  v-model="
                                    newReservationModalForm.invoices.abroad
                                      .country
                                  "
                                  :reduce="(item) => item"
                                  :items="dummy.countries"
                                  item-value="value"
                                  item-text="name"
                                  :label="$t('country')"
                                  :placeholder="$t('select_country')"
                                  id="id"
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('province')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('province')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.abroad
                                      .province
                                  "
                              /></validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <validation-provider
                                mode="eager"
                                :name="$t('post_code')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('post_code')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.abroad
                                      .postCode
                                  "
                              /></validation-provider>
                            </v-col>
                            <v-col cols="12" lg="12">
                              <validation-provider
                                mode="eager"
                                :name="$t('address')"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('address')"
                                  class="font-weight-bold"
                                  v-model="
                                    newReservationModalForm.invoices.abroad
                                      .address
                                  "
                              /></validation-provider>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row v-if="!userPermissionModal">
                        <v-col cols="12">
                          <CInputCheckbox
                            v-for="(option, optKey) in permissions"
                            :key="option + optKey"
                            :inline="true"
                            :label="option.label"
                            :value="option.value"
                            :checked="
                              newReservationModalForm.driverInfo.permissions.some(
                                (item) => item === option.value
                              )
                            "
                            @update:checked="
                              updatePermissionsCheckBox(option.value)
                            "
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <validation-provider
                            mode="eager"
                            :name="$t('flight_number')"
                            :rules="boardingPassRequired ? 'required' : ''"
                          >
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              :label="$t('flight_number')"
                              class="font-weight-bold"
                              v-model="
                                newReservationModalForm.driverInfo.flightNo
                              "
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-divider class="my-0"></v-divider>
                      <v-row>
                        <v-col cols="12" lg="12">
                          <h5 class="color-red">
                            {{ $t("generalDescription") }}
                          </h5>
                          <validation-provider
                            mode="eager"
                            :name="$t('vendorDescription')"
                          >
                            <v-textarea
                              outlined
                              rows="2"
                              dense
                              hide-details
                              :label="$t('vendorDescription')"
                              class="font-weight-bold"
                              v-model="newReservationModalForm.description"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </validation-observer>
                  </div>
                </v-card>

                <!-- ÖDEME BİLGİLERİ BİTİŞ -->
              </v-col>
              <v-col cols="12" lg="5" md="12" sm="12">
                <v-card elevation="10">
                  <v-toolbar dense dark color="grey darken-1" elevation="0">
                    <v-toolbar-title
                      ><strong> {{ $t("payment") }}</strong></v-toolbar-title
                    >
                  </v-toolbar>
                  <v-row>
                    <v-col>
                      <v-row
                        v-if="
                          newReservationModalForm.searchPrice.vendor
                            .isFullCredit || 1 == 1
                        "
                      >
                        <v-col cols="12" lg="12">
                          <v-select
                            outlined
                            dense
                            hide-details
                            name="payment-type"
                            :items="reservationTypes"
                            v-model="newReservationModalForm.reservationType"
                            item-text="label"
                            item-value="value"
                            :label="$t('reservation_type')"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" lg="12">
                          <validation-provider
                            mode="eager"
                            :name="$t('payment_type')"
                            rules="required"
                          >
                            <v-select
                              outlined
                              dense
                              hide-details
                              name="payment-type"
                              v-model="paymentType"
                              :items="typesOfPaymentAvailableOptions"
                              item-text="label"
                              item-value="value"
                              @change="
                                openSelectLink = paymentType === 'smsPos'
                              "
                              :label="$t('payment_type')"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col v-if="openSelectLink" cols="12" lg="12">
                          <v-select
                            outlined
                            dense
                            hide-details
                            name="payment-type"
                            v-model="linkSendType"
                            :items="typesOfLinkPaymentOptions"
                            item-text="label"
                            item-value="value"
                          />
                        </v-col>

                        <v-col cols="12" lg="12">
                          <v-select
                            :items="sendMailItems"
                            item-text="tr"
                            item-value="val"
                            outlined
                            dense
                            hide-details
                            label="Sürücüye Email Gönder"
                            v-model="newReservationModalForm.sendMailToDriver"
                          />
                        </v-col>
                        <v-col cols="12" lg="12">
                          <v-select
                            :items="sendSmsItems"
                            item-text="tr"
                            item-value="val"
                            outlined
                            dense
                            hide-details
                            label="Sürücüye Sms Gönder"
                            v-model="newReservationModalForm.sendSmsToDriver"
                          />
                        </v-col>
                      </v-row>
                      <div v-if="calculatedPrices?.totalRentalPrice?.TRY">
                        <v-row class="mb-0">
                          <v-col class="col-8 font-weight-bold">
                            {{ $t("rental_period") }}</v-col
                          >
                          <v-col class="col-4 text-right font-weight-bold">
                            {{ newReservationModalForm.searchPrice.totalDays }}
                            {{ $t("day") }}
                          </v-col>
                        </v-row>
                        <v-divider class="m-0"></v-divider>
                        <v-row class="mb-0">
                          <v-col class="col-8 font-weight-bold">
                            {{ $t("rental_price") }}
                            <small>{{ $t("daily") }}</small>
                            <div
                              class="pa-0"
                              v-if="
                                newReservationModalForm.searchPrice.vendor
                                  .overSelling
                              "
                              @click="editingDailyPrice = !editingDailyPrice"
                            >
                              <small
                                ><v-icon x-small>mdi-pencil</v-icon>
                                {{ $t("change") }}</small
                              >
                            </div>
                          </v-col>
                          <v-col
                            class="col-4 text-right font-weight-bold"
                            v-if="!editingDailyPrice"
                            >{{
                              func
                                .toMoneyFormat(
                                  calculatedPrices?.dailyRentalPrice[
                                    $store.state.currency
                                  ],
                                  true
                                )
                                .toLocaleString("tr-TR", {
                                  style: "currency",
                                  currency: $store.state.currency,
                                }) || null
                            }}
                          </v-col>
                          <v-col
                            class="col-4 text-right font-weight-bold"
                            v-if="editingDailyPrice"
                            ><v-text-field
                              hide-details
                              dense
                              @change="setLoadingPrice"
                              v-model="
                                calculatedPrices.dailyRentalPrice[
                                  $store.state.currency
                                ]
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider class="m-0"></v-divider>
                        <v-row class="mb-0">
                          <v-col class="col-8 font-weight-bold">
                            {{ $t("rental_price") }}
                            <small>{{ $t("total") }}</small></v-col
                          >
                          <v-col class="col-4 text-right font-weight-bold">
                            {{
                              func
                                .toMoneyFormat(
                                  calculatedPrices?.totalRentalPrice[
                                    $store.state.currency
                                  ],
                                  true
                                )
                                .toLocaleString("tr-TR", {
                                  style: "currency",
                                  currency: $store.state.currency,
                                }) || null
                            }}
                          </v-col>
                        </v-row>
                        <v-divider
                          v-if="calculatedPrices.selectedExtras > 0"
                          class="m-0"
                        ></v-divider>
                        <v-row
                          class="mb-3"
                          v-for="(
                            data, key
                          ) in calculatedPrices?.selectedExtras"
                          :key="key"
                        >
                          <v-col :class="'col-8' + (key % 2 ? ' bg-grey' : '')">
                            <font-awesome-icon
                              :icon="['fas', 'plus']"
                              class="mr-2"
                            />
                            {{ data.name }}
                          </v-col>
                          <v-col
                            :class="
                              'col-4 text-right font-weight-bold' +
                              (key % 2 ? ' bg-grey' : '')
                            "
                            >{{
                              func
                                .toMoneyFormat(
                                  data.totalPrice[$store.state.currency]
                                )
                                .toLocaleString("tr-TR", {
                                  style: "currency",
                                  currency: $store.state.currency,
                                }) || null
                            }}</v-col
                          >
                        </v-row>
                        <v-row
                          class="mb-0"
                          v-if="
                            calculatedPrices?.totalExtraPrice[
                              $store.state.currency
                            ] > 0
                          "
                        >
                          <v-col class="col-8 font-weight-bold">
                            {{ $t("total_extras") }}</v-col
                          >
                          <v-col class="col-4 text-right font-weight-bold">{{
                            func
                              .toMoneyFormat(
                                calculatedPrices?.totalExtraPrice[
                                  $store.state.currency
                                ]
                              )
                              .toLocaleString("tr-TR", {
                                style: "currency",
                                currency: $store.state.currency,
                              }) || null
                          }}</v-col>
                        </v-row>

                        <v-row
                          class="mb-3"
                          v-if="
                            calculatedPrices?.dropPrice[$store.state.currency] >
                            0
                          "
                        >
                          <v-col class="col-8 font-weight-bold">{{
                            $t("drop_off_price")
                          }}</v-col>
                          <v-col class="col-4 text-right font-weight-bold">
                            {{
                              func
                                .toMoneyFormat(
                                  calculatedPrices?.dropPrice[
                                    $store.state.currency
                                  ]
                                )
                                .toLocaleString("tr-TR", {
                                  style: "currency",
                                  currency: $store.state.currency,
                                })
                            }}
                            ₺
                          </v-col>
                        </v-row>
                        <v-divider class="m-0"></v-divider>

                        <v-row class="mb-3">
                          <v-col class="col-8 font-weight-bold text-danger">
                            {{ $t("now_payment_price") }}
                          </v-col>
                          <v-col class="col-4 text-right font-weight-bold">
                            {{
                              func
                                .toMoneyFormat(
                                  calculatedPrices?.payNow[
                                    $store.state.currency
                                  ]
                                )
                                .toLocaleString("tr-TR", {
                                  style: "currency",
                                  currency: $store.state.currency,
                                })
                            }}
                          </v-col>
                        </v-row>
                        <v-divider class="m-0"></v-divider>
                        <v-row class="mb-3">
                          <v-col class="col-8 font-weight-bold">
                            {{ $t("delivery_payment_price") }}
                          </v-col>
                          <v-col class="col-4 text-right font-weight-bold">
                            {{
                              calculatedPrices?.payLater[
                                $store.state.currency
                              ].toLocaleString("tr-TR", {
                                style: "currency",
                                currency: $store.state.currency,
                              })
                            }}
                          </v-col>
                        </v-row>
                        <v-divider class="m-0"></v-divider>
                        <v-row class="mb-3">
                          <v-col class="col-8 font-weight-bold">
                            {{ $t("total_payment_price2") }}

                            <br />
                            <small
                              v-if="
                                newReservationModalForm.reservationType !=
                                'fullCredit'
                              "
                            >
                              {{ $t("excluding_deposit") }}
                            </small>
                          </v-col>
                          <v-col class="col-4 text-right font-weight-bold">
                            {{
                              calculatedPrices?.totalPrice[
                                $store.state.currency
                              ].toLocaleString("tr-TR", {
                                style: "currency",
                                currency: $store.state.currency,
                              })
                            }}
                          </v-col>
                        </v-row>
                        <v-divider class="m-0"></v-divider>

                        <v-row class="mb-3">
                          <v-col class="col-8">
                            {{ $t("deposit") }}
                          </v-col>
                          <v-col class="col-4 text-right font-weight-bold">
                            {{
                              calculatedPrices?.provision[
                                $store.state.currency
                              ]?.toLocaleString("tr-TR", {
                                style: "currency",
                                currency: $store.state.currency,
                              })
                            }}
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions class="justify-end mt-3">
                  <v-btn
                    color="error"
                    @click="
                      (newReservationModal = false),
                        (additionalExtrasItems = [])
                    "
                  >
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    color="success"
                    :disabled="createReservationBtnStatus"
                    @click="createReservation()"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'circle-notch']"
                      size="lg"
                      class="spin mr-2"
                      v-show="createReservationBtnStatus"
                    />
                    <span v-if="paymentType == 'noneSecurePos'">
                      {{ $t("continue") }}
                    </span>
                    <span v-else-if="paymentType == 'smsPos'">
                      {{ $t("continue") }}
                    </span>
                    <span v-else> {{ $t("complete_the_reservation") }} </span>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog max-width="800px" v-model="usersModal">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ usersModalTitle }}</span>
        </v-card-title>

        <ag-grid-vue
          style="width: 100%; height: 450px"
          class="ag-theme-balham"
          :columnDefs="usersModalColumnDefs"
          :defaultColDef="usersModalDefaultColDef"
          :rowData="usersModalRowData"
          :getContextMenuItems="getUserModalGridContextMenuItems"
          :statusBar="usersModalStatusBar"
          @grid-ready="onGridReady"
          @rowDoubleClicked="onRowDoubleClickedUserModal"
        />

        <v-card-actions>
          <v-btn @click="usersModal = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="800px" v-model="driversModal">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ driversModalTitle }}</span>
        </v-card-title>

        <ag-grid-vue
          style="width: 100%; height: 500px"
          class="ag-theme-balham"
          :columnDefs="driversModalColumnDefs"
          :defaultColDef="driversModalDefaultColDef"
          :rowData="driversModalRowData"
          :getContextMenuItems="getDriverModalGridContextMenuItems"
          :statusBar="driversModalStatusBar"
          @grid-ready="onGridReady"
          @rowDoubleClicked="onRowDoubleClickedDriverModal"
        />
        <v-card-actions>
          <CButton color="danger" @click="driversModal = false">
            {{ $t("close") }}</CButton
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="manipulateInfoModal" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> {{ $t("warning") }}! </v-card-title>
          <v-card-text>{{ $t("warnin_info") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="manipulateInfoModal = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="clickAggreInfoManipulate()"
            >
              {{ $t("accept") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Manuel Rezervasyon Modal -->
    <CModal :show.sync="isManually" title="Rezervasyon Ekle - Manuel" size="xl">
      <CForm @submit.prevent="searchBid">
        <v-row class="mt-4 mb-4">
          <v-col md="4">
            <v-autocomplete
              dense
              hide-details
              outlined
              clearable
              v-model="manuallySearchBidSelectedVendor"
              :items="manuallySearchBidSelectVendorOptions"
              item-text="label"
              item-value="value"
              placeholder="Tedarikçi seç"
              label="Tedarikçi seç"
            ></v-autocomplete>
          </v-col>
          <v-col md="4">
            <VueCtkDateTimePicker
              id="pickup_datetime"
              no-shortcuts
              no-header
              format="YYYY-MM-DD HH:mm"
              formatted="Do MMM YYYY ddd HH:mm"
              :minuteInterval="offsetMinute"
              v-model="manuallySearchBidForm.delivery.datetime"
              :locale="locale"
              :label="$t('pickup_time')"
            /> </v-col
          ><v-col md="4">
            <v-autocomplete
              dense
              hide-details
              outlined
              clearable
              v-model="manuallySearchBidSelectedDeliveryLocationSlug"
              :items="manuallySearchBidSelectLocationOptions"
              item-text="label"
              item-value="value"
              :placeholder="$t('pickup_location')"
              :label="$t('pickup_location')"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col md="2">
            <v-text-field
              v-model="manuallySearchBidForm.price.vendorDailyPrice"
              label="Tedarikçi Günlük Fiyat"
              required
              hide-details
              type="number"
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="manuallySearchBidForm.price.rentalDailyPrice"
              label="Müşteri Günlük Fiyat"
              required
              :error-messages="
                manuallySearchBidForm.price.rentalDailyPrice <
                manuallySearchBidForm.price.vendorDailyPrice
                  ? 'Fiyatı Kontrol Ediniz'
                  : null
              "
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <VueCtkDateTimePicker
              id="return_datetime"
              no-shortcuts
              no-header
              format="YYYY-MM-DD HH:mm"
              formatted="Do MMM YYYY ddd HH:mm"
              :minDate="manuallySearchBidForm.delivery.datetime"
              :minuteInterval="offsetMinute"
              v-model="manuallySearchBidForm.return.datetime"
              :locale="locale"
              :label="$t('return_time')"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              hide-details
              outlined
              clearable
              v-model="manuallySearchBidSelectedReturnLocationSlug"
              :items="manuallySearchBidSelectLocationOptions"
              item-text="label"
              item-value="value"
              :placeholder="$t('return_location')"
              :label="$t('return_location')"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col md="4">
            <v-autocomplete
              dense
              hide-details
              outlined
              clearable
              v-model="manuallySearchBidSelectedCarFuel"
              :items="manuallySearchBidSelectCarFuelOptions"
              item-text="label"
              item-value="value"
              placeholder="Araç Yakıt Tipi Seç"
              label="Araç Yakıt Tipi Seç"
              @change="
                getCar(
                  manuallySearchBidSelectedCarFuel,
                  manuallySearchBidSelectedCarTransmission
                )
              "
            ></v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-autocomplete
              dense
              hide-details
              outlined
              clearable
              v-model="manuallySearchBidSelectedCarTransmission"
              :items="manuallySearchBidSelectCarTransmissionOptions"
              item-text="label"
              item-value="value"
              placeholder="Araç Vites Tipi Seç"
              label="Araç Vites Tipi Seç"
              @change="
                getCar(
                  manuallySearchBidSelectedCarFuel,
                  manuallySearchBidSelectedCarTransmission
                )
              "
            ></v-autocomplete>
          </v-col>

          <v-col md="4">
            <v-autocomplete
              dense
              hide-details
              outlined
              clearable
              v-model="manuallySearchBidForm.car.car_id"
              :items="manuallySearchBidSelectCarOptions"
              item-text="label"
              placeholder="Araçlar"
              label="Araçlar"
            >
              <!-- <template v-slot:item="{ item }">
                        <div
                          style="width: 100%"
                          class="d-flex justify-space-between">
                          <div>{{ item.label }}</div>
                          <img
                            :src="
                              item?.image == null
                                ? ENV_URL_CARS + defaultCarImage
                                : `https://cdn.renticar.com/cars/${item.image}`
                            "
                            alt="Item Image"
                            class="mr-2"
                            height="24"
                            width="24" />
                        </div>
                      </template> -->
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mb-4 justify-center">
          <v-col md="4">
            <v-autocomplete
              v-model="manuallySearchBidForm.car.deliveryType"
              :items="manuallySearchBidSelectDeliveryTypeOptions"
              item-text="tr"
              item-value="val"
              label="Teslim Tipi  seç"
              placeholder="Teslim Tipi seç"
            >
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-autocomplete
              v-model="manuallySearchBidForm.car.fuelPolicy"
              :items="manuallySearchBidSelectFuelPolicyOptions"
              item-text="tr"
              item-value="val"
              label="Yakıt Politikası  seç"
              placeholder="Yakıt Politikasıseç"
            >
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-text-field
              label="Farklı Yere Bırakma Ücreti"
              type="number"
              required
              v-model="manuallySearchBidForm.price.dropPrice"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mb-4 justify-center">
          <v-col md="3">
            <v-text-field
              label="Depozito"
              type="number"
              required
              v-model="manuallySearchBidForm.price.provision"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              label="Sürücü Yaşı"
              type="number"
              required
              v-model="manuallySearchBidForm.rules.driverAge"
              hide-details="auto"
              :rules="[
                (value) => value >= 18 || 'Sürücü Yaşı en az 18 olmalıdır.',
              ]"
            ></v-text-field>
          </v-col>

          <v-col md="3">
            <v-text-field
              v-if="
                moment(manuallySearchBidForm.return.datetime).diff(
                  moment(manuallySearchBidForm.delivery.datetime),
                  'days'
                ) >= 30
              "
              label="Tedarikçi Aylık Komisyon Oranı"
              type="number"
              v-model="manuallySearchBidForm.vendor_commision_monthly"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-else
              label="Tedarikçi Komisyon Oranı"
              type="number"
              v-model="manuallySearchBidForm.vendor_commision_daily"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              label="Toplam Km Limiti"
              required
              v-model="manuallySearchBidForm.rules.totalRangeLimit"
              hide-details
              type="number"
              :rules="[
                (value) => value >= 0 || 'Km Limitini 0 dan küçük olamaz.',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
      </CForm>
      <template #footer>
        <v-btn
          :disabled="
            searchBidInputDisabled ||
            manuallySearchBidForm.rules.driverAge < 18 ||
            manuallySearchBidForm.rules.totalRangeLimit < 1
          "
          type="submit"
          block
          color="primary"
          @click="searchBid"
          :loading="searchBidInputDisabled"
        >
          <strong> KAYDET ve DEVAM ET </strong>
        </v-btn>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import Func from "../../func";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { IMaskComponent } from "vue-imask";
import VCreditCard from "v-credit-card"; // https://www.npmjs.com/package/v-credit-card.
import "v-credit-card/dist/VCreditCard.css"; // https://www.npmjs.com/package/v-credit-card
import { RoleProvider } from "../../provider";

import printDoc from "../../components/vue-ag-grid-pdf-make-master/pdfExport/printDoc";

var _ = require("lodash");
let localThis;
let options = {
  inputOptions: {
    name: "phone",
    placeholder: localThis?.$t("phone_number"),
  },
  validCharactersOnly: true,
  mode: "national",
};
Vue.use(VueTelInput, options);

axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

// Genel Ayarlar
const minBetweenDay = 1; // İki tarih arasındaki minimum gün
const maxMonth = 3; // Maksimum ay seçimi
const offsetDay = 1; // 0ffset gün değeri => Eklenecek gün sayısı. Gün eklenmeyecekse 0 bırakılır.
const offsetMinute = 30; // Offset saat değeri => Saat seçimindeki dakika aralığı
const fullProtectionPrice = 50; // Tam koruma fiyatı
const confirmCodeLength = 5; // Onay kodu uzunluğu
const reservationCodeLength = 10; // Rezervasyon kodu uzunluğu

let deliveryDateTime, deliveryMinDateTime, deliveryMaxDateTime;
let returnDateTime, returnMinDateTime, returnMaxDateTime;

export default {
  name: "NewReservation",
  components: {
    AgGridVue,
    VCreditCard,
    "imask-input": IMaskComponent,
    RoleProvider,
  },
  data() {
    return {
      gridApi: null,
      extrasLoading: false,
      gridColumnApi: null,
      boardingPassRequired: null,
      telefondogrumu: null,
      manipulateInfoModal: false,
      partnerPromoCodeStatus: null,
      openSelectLink: false,
      resTypeItems: [
        {
          tr: "Normal",
          val: "normal",
        },
        {
          tr: "Full Credit",
          val: "fullCredit",
        },
        {
          tr: "Ofiste Ödeme",
          val: "payOnArrival",
        },
        {
          tr: "Parçalı Öde",
          val: "partialPayment",
        },
      ],
      resTypeFilter: [],
      resData: [],
      defaultPaymentTypes: [
        { label: this.$t("virtual_pos"), value: "noneSecurePos" },
        // {
        //   label: this.$t("payment_via_sms_link"),
        //   value: "smsPos",
        // },
        {
          label: this.$t("PayLink"),
          value: "smsPos",
        },
        { label: this.$t("current_account"), value: "debit" },
      ],
      //prices
      returnPaymentPrice: 0,
      currency: {
        name: "TRY",
        color: "info",
        symbol: "₺",
        longname: this.$t("turkish_currency"),
        icon: "fa-lira-sign",
      },
      locale: this.$store.state.locale,
      totalPaymentPrice: 0,
      nowPaymentPrice: 0,
      provisionPrice: 0,
      calculatedPrices: {},
      editingDailyPrice: false,
      isDifferentReturnLocation: false,
      dummy: {
        countries: [],
        cities: [],
      },
      dataDropDown: {
        cities: [],
      },
      promo_code: "",
      createReservationBtnStatus: false,
      additionalExtrasItems: [],
      // GENEL
      rowEmpty: false,
      extraInfo: false,
      ENV_URL_VENDOR: process.env.VUE_APP_CDN_URL_VENDOR,
      ENV_URL_CARS: process.env.VUE_APP_CDN_URL_CARS,
      func: Func,
      minBetweenDay: minBetweenDay,
      maxMonth: maxMonth,
      offsetDay: offsetDay,
      offsetMinute: offsetMinute,
      deliveryDateTime: deliveryDateTime,
      deliveryMinDateTime: moment().local().format("YYYY-MM-DD HH:mm"),
      deliveryMaxDateTime: deliveryMaxDateTime,
      returnDateTime: returnDateTime,
      returnMinDateTime: moment().format("YYYY-MM-DD HH:mm"),
      returnMaxDateTime: returnMaxDateTime,
      fullProtectionPrice: fullProtectionPrice,
      bilType: [
        {
          value: "individual",
          label: this.$t("individual"),
        },
        {
          value: "individualCompany",
          label: this.$t("individualCompany"),
        },
        {
          value: "company",
          label: this.$t("company"),
        },
        {
          value: "abroad",
          label: this.$t("abroadCompany"),
        },
      ],
      reservationTypes: [],
      typesOfLinkPaymentOptions: [
        { label: this.$t("payment_via_sms_link"), value: "LinkForSms" },
        { label: this.$t("payment_via_email_link"), value: "LinkForEmail" },
      ],
      //-----payment------
      typesOfPaymentAvailableOptions: [
        { label: "noneSecurePos", value: "noneSecurePos" },
        // { label: "ivrPos", value: "ivrPos" },//sonradan gelecek
        { label: "smsPos", value: "smsPos" },
        { label: "debit", value: "debit" },
      ],
      paymentModalShow: false,
      paymentType: "",
      linkSendType: "LinkForSms",
      //-----payment------

      // AG GRID
      bidDetailGridShow: false,
      columnDefs: [
        {
          field: "car.image",
          cellRenderer: (params) => {
            return (
              params.value &&
              '<img src="' +
                this.ENV_URL_CARS +
                params.value +
                '" class="my-1" style="width: auto; height: 35px">'
            );
          },
          headerName: this.$t("image"),
          width: 110,
          pdfExportOptions: {
            skipColumn: true,
          },
        },

        {
          field: "vendor.logo",
          valueGetter: (params) => {
            return params.data.vendor.name;
          },
          cellRenderer: (params) => {
            return (
              params.data.vendor.logo &&
              '<img src="' +
                this.ENV_URL_VENDOR +
                params.data.vendor.logo +
                '" class="my-1" style="width: auto; height: 35px">'
            );
          },
          headerName: this.$t("company"),
          width: 100,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "vendor.name",
          hide: true,
          cellRenderer: (params) => {
            var value = params.data.vendor.name;
            return "<strong>" + value + "</strong>";
          },
          headerName: this.$t("company_name"),
          width: 150,
        },

        {
          field: `campaignData.info.name.tr`,

          headerName: this.$t("campaign"),
          width: 120,
          pdfExportOptions: {
            skipColumn: true,
          },
        },

        {
          field: "office.boardingPass",

          headerName: this.$t("boarding_pass_obligation"),
          width: 120,
          pdfExportOptions: {
            skipColumn: true,
          },

          cellRenderer: (params) => {
            if (
              params.data.returnOffice.boardingPass ||
              params.data.office.boardingPass
            ) {
              return (
                "<strong class='red--text'>" +
                this.$t("compulsory") +
                "</strong>"
              );
            } else {
              return this.$t("compulsory_not");
            }
          },
        },

        {
          field: "car.brand",
          headerName: this.$t("brand"),
          width: 110,
          pdfExportOptions: {
            skipColumn: true,
          },
        },
        {
          field: "car.model",
          cellRenderer: (params) => {
            var value = params.data.car.model;
            return "<strong>" + value + "</strong>";
          },
          headerName: "Model",
          width: 110,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },

        {
          field: "car.class",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.data.car.class];
          },
          headerName: this.$t("class"),
          width: 100,
          pdfExportOptions: {
            skipColumn: true,
          },
        },
        {
          field: "car.size",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.data.car.size];
          },
          headerName: this.$t("size"),
          width: 100,
          pdfExportOptions: {
            skipColumn: true,
          },
        },

        {
          field: "car.fuel",
          cellRenderer: (params) => {
            return (
              "<strong>" +
              this.$store.state.ceviri[params.data.car.fuel] +
              "</strong>"
            );
          },
          headerName: this.$t("fuel"),
          width: 90,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "car.transmission",
          cellRenderer: (params) => {
            return (
              "<strong>" +
              this.$store.state.ceviri[params.data.car.transmission] +
              "</strong>"
            );
          },

          headerName: this.$t("gear"),
          width: 120,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "totalDays",
          headerName: this.$t("day"),
          cellRenderer: (params) => {
            return params.value;
          },
          width: 90,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "vendor.freeCancelInfo.minHoursInfo",
          headerName: this.$t("free_cancellation"),
          cellRenderer: (params) => {
            if (params.value) {
              return (
                "<strong>" +
                this.$t("free_cancel_text", {
                  minHoursInfo: params.value,
                }) +
                "</strong>"
              );
            } else {
              return this.$t("available");
            }
          },
          width: 150,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "vendor.noEarlyReturn",
          headerName: this.$t("early_return_1"),
          cellRenderer: (params) => {
            if (params.value) {
              return "<strong>" + this.$t("none") + "</strong>";
            } else {
              return this.$t("available");
            }
          },
          width: 120,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "vendor.noNoShowReturn",
          headerName: this.$t("noShowCancelled"),
          cellRenderer: (params) => {
            if (params.value) {
              return "<strong>" + this.$t("none") + "</strong>";
            } else {
              return this.$t("available");
            }
          },
          width: 120,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "dailyPrice",
          type: "rightAligned",
          pinned: "right",
          cellRenderer: (params) => {
            return this.func
              .toMoneyFormat(
                params.data.pricing.dailyPrice[this.$store.state.currency],
                true
              )
              .toLocaleString("tr-TR", {
                style: "currency",
                currency: this.$store.state.currency,
              });
          },
          //cellStyle: params => { return { fontWeight: 'bold', fontSize: '0.9rem' } },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return params.data.pricing.dailyPrice[this.$store.state.currency];
          },
          headerName: this.$t("daily"),
          width: 120,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },
        {
          field: "totalPrice",
          type: "rightAligned",
          pinned: "right",
          cellRenderer: (params) => {
            return this.func
              .toMoneyFormat(
                params.data.pricing.totalPrice[this.$store.state.currency],
                true
              )
              .toLocaleString("tr-TR", {
                style: "currency",
                currency: this.$store.state.currency,
              });
          },
          //cellStyle: params => { return { fontWeight: 'bold', fontSize: '0.9rem' } },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyonkkk
            return params.data.pricing.totalPrice.TRY;
          },
          headerName: this.$t("total"),
          width: 120,
          pdfExportOptions: {
            styles: {
              fontSize: 10,
            },
          },
        },

        {
          field: "vendor.reservationTypes.fullCredit",
          cellRenderer: (params) => {
            return params.value ? "✔" : "";
          },
          headerName: "Full Credit",
          width: 120,
          pdfExportOptions: {
            skipColumn: true,
          },
          cellStyle: (params) =>
            params.value ? { color: "white", backgroundColor: "green" } : {},
        },
        {
          field: "vendor.reservationTypes.payOnArrival",
          cellRenderer: (params) => {
            return params.value ? "✔" : "";
          },
          headerName: "PayOnArrival",
          width: 120,
          pdfExportOptions: {
            skipColumn: true,
          },
          cellStyle: (params) =>
            params.value ? { color: "white", backgroundColor: "green" } : {},
        },
        {
          field: "vendor.overSelling",
          cellRenderer: (params) => {
            return params.value ? "✔" : "";
          },
          headerName: "OverSelling",
          width: 120,
          pdfExportOptions: {
            skipColumn: true,
          },
          cellStyle: (params) =>
            params.value ? { color: "white", backgroundColor: "green" } : {},
        },
        {
          field: "provision",
          type: "rightAligned",
          cellRenderer: (params) => {
            return this.func
              .toMoneyFormat(
                params.data.pricing.provision[this.$store.state.currency]
              )
              .toLocaleString("tr-TR", {
                style: "currency",
                currency: this.$store.state.currency,
              });
          },
          //cellStyle: params => { return { color: '#2eb85c', fontWeight: 'bold', fontSize: '0.9rem' } },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return (
              params.data.pricing.provision[this.$store.state.currency] || ""
            );
          },
          headerName: this.$t("provision"),
          pdfExportOptions: {
            skipColumn: true,
          },
          width: 110,
        },
        {
          field: "driverAge",
          type: "rightAligned",
          cellRenderer: (params) => {
            return "+" + params.data.rules.driverAge;
          },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return params.data.rules.driverAge;
          },
          headerName: this.$t("age"),
          pdfExportOptions: {
            skipColumn: true,
          },

          width: 75,
        },

        {
          field: "rules.licenseYears",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.data.rules.licenseYears + " " + this.$t("year");
          },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return params.data.rules.licenseYears;
          },
          headerName: this.$t("driving_license"),
          pdfExportOptions: {
            skipColumn: true,
          },

          width: 75,
        },
        {
          field: "dailyRangeLimit",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.data.rules.dailyRangeLimit === 0
              ? this.$t("unlimited")
              : params.data.rules.dailyRangeLimit ===
                params.data.rules.dailyRangeLimit
              ? params.data.rules.totalRangeLimit + " KM"
              : params.data.rules.dailyRangeLimit +
                "/" +
                params.data.rules.totalRangeLimit;
          },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return params.data.rules.dailyRangeLimit;
          },
          headerName: this.$t("km_limit"),
          pdfExportOptions: {
            skipColumn: true,
          },

          width: 100,
        },

        {
          field: "dropPrice",
          type: "rightAligned",
          cellRenderer: (params) => {
            return this.func
              .toMoneyFormat(
                params.data.pricing.dropPrice[this.$store.state.currency] || 0
              )
              .toLocaleString("tr-TR", {
                style: "currency",
                currency: this.$store.state.currency,
              });
          },
          //cellStyle: params => { return { color: '#2eb85c', fontWeight: 'bold', fontSize: '0.9rem' } },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return (
              params.data.pricing.provision[this.$store.state.currency] || 0
            );
          },
          headerName: this.$t("drop_off_price"),
          pdfExportOptions: {
            skipColumn: true,
          },

          width: 200,
        },

        // TESLİMAT

        {
          field: "car.fuelPolicy",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.data.car.fuelPolicy];
          },
          headerName: this.$t("fuel_policy"),
          pdfExportOptions: {
            skipColumn: true,
          },

          width: 150,
        },

        {
          field: "returnOffice.deliveryType",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[
              params.data.returnOffice.deliveryType
            ];
          },

          headerName: this.$t("delivery"),
          pdfExportOptions: {
            skipColumn: true,
          },

          width: 120,
        },
        {
          field: "car.seat",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.data.car.seat + this.$t("seat");
          },
          headerName: this.$t("seat"),
          pdfExportOptions: {
            skipColumn: true,
          },

          width: 110,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab"],
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: this.$t("columns"),
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: this.$t("filters"),
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü

      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      // ARAÇ ARAMA
      searchBidCollapsed: true,
      searchBidInputDisabled: false,
      searchBidLocationList: [],
      searchBidSelectLocationOptions: [],
      searchBidSelectedDeliveryLocationSlug: "",
      searchBidSelectedReturnLocationSlug: "",
      partnerID: this.$store.state.GetServerData?.partnerID || null,
      selectedPartnerInfo: {},
      partnerInfo: [],
      searchBidForm: {
        delivery: {
          group: "",
          name: "",
          slug: "",
          datetime: this.moment()
            .add(1, "days")
            .local()
            .format("YYYY-MM-DD 10:00"),
        },
        return: {
          group: "",
          name: "",
          slug: "",
          datetime: this.moment()
            .add(4, "days")
            .local()
            .format("YYYY-MM-DD 10:00"),
        },
      },
      //MANUELREZERVASYON
      isManually: false,
      manuallySearchBidSelectVendorOptions: [],
      manuallySearchBidSelectVendorCommission: [],
      manuallySearchBidSelectedVendor: "",
      manuallySearchBidSelectLocationOptions: [],
      manuallySearchBidSelectedDeliveryLocationSlug: "",
      manuallySearchBidSelectedReturnLocationSlug: "",
      manuallySearchBidSelectCarOptions: [],
      manuallySearchBidSelectedCarTransmission: "",
      manuallySearchBidSelectedCarFuel: "",
      manuallySearchBidSelectCarFuelOptions: [
        {
          value: "",
          label: "Seçiniz",
        },
        {
          value: "gas",
          label: "Benzin",
        },
        {
          value: "diesel",
          label: "Dizel",
        },
        {
          value: "lpg",
          label: "LPG",
        },
        {
          value: "hybrit",
          label: "Hibrit",
        },
        {
          value: "electric",
          label: "Elektrikli",
        },
        {
          value: "unspecified",
          label: "Belirtilmemiş",
        },
      ],

      manuallySearchBidSelectCarTransmissionOptions: [
        {
          value: "",
          label: "Seçiniz",
        },
        {
          value: "manuel",
          label: "Manuel",
        },
        {
          value: "automatic",
          label: "Otomatik",
        },
      ],

      manuallySearchBidSelectDeliveryTypeOptions: [
        {
          val: "centralOffice",
          en: "Central Office",
          tr: "Ofisten Teslim",
        },
        { val: "welcoming", en: "Meeting Point", tr: "Buluşma Noktası" },
        {
          val: "freeShuttle",
          en: "Free Shuttle",
          tr: "Ücretsiz Servis",
        },
        {
          val: "inTerminal",
          en: "In Terminal",
          tr: "Havalimanı Ofisi",
        },
      ],
      manuallySearchBidSelectFuelPolicyOptions: [
        {
          val: "sameLevel",
          en: "Same Level",
          tr: "Aynı Seviye Yakıt",
        },
        {
          val: "full",
          en: "Full",
          tr: "Dolu Depo Yakıt",
        },
      ],
      manuallySearchBidForm: {
        delivery: {
          group: "",
          name: "",
          slug: "",
          datetime: this.moment()
            .add(1, "days")
            .local()
            .format("YYYY-MM-DD 10:00"),
        },
        return: {
          group: "",
          name: "",
          slug: "",
          datetime: this.moment()
            .add(4, "days")
            .local()
            .format("YYYY-MM-DD 10:00"),
        },
        price: {
          vendorDailyPrice: 0,
          rentalDailyPrice: 0,
          dropPrice: 0,
          provision: 0,
        },
        car: {
          deliveryType: "centralOffice",
          fuelPolicy: "full",
          car_id: "",
        },
        vendor_apiSlug: "",
        vendor_commision_daily: null,
        vendor_commision_monthly: null,
        rules: {
          driverAge: 21,
          dailyRangeLimit: 0,
          totalRangeLimit: 0,
        },
      },
      sendMailItems: [
        {
          tr: "Tutarlı Mail Gönder",
          en: "Send consistent mail",
          val: "withPrice",
        },
        {
          tr: "Tutarsız Mail Gönder",
          en: "Send inconsistent mail",
          val: "noPrice",
        },
        {
          tr: "Mail Gönderme",
          en: "Do not send mail",
          val: "none",
        },
      ],

      sendSmsItems: [
        {
          tr: "Sms Gönder",
          en: "Send sms",
          val: "send",
        },
        {
          tr: "Sms Gönderme",
          en: "Do not send sms",
          val: "none",
        },
      ],

      // YENİ REZERVASYON MODAL
      newReservationModal: false,
      newReservationModalFullProtection: false,
      newReservationModalSelectUser: true,
      newReservationModalSelectedDriverPhone: "",
      newReservationModalCardDesign: "row", // Kart componenti içierisinde bulunan görselim yandamı yukarıdamı duracağını belirliyor (row/column). Boyuta göre handleResize methodu içerisinde değiştiriliyor
      newReservationModalTranslations: {
        // Kart componenti içerisinde gösterilen başlıklar değiştiriliyor.
        name: {
          label: "Ad Soyad",
          placeholder: this.$t("enter_name_and_surname"),
        },
        card: {
          label: "Kart Numarası",
          placeholder: this.$t("enter_card_number"),
        },
        expiration: {
          label: this.$t("date"),
        },
        security: {
          label: "CVV",
          placeholder: "CVV",
        },
      },
      selectableCampaigns: [],
      permissions: [
        {
          value: "sms",
          label: "SMS",
        },
        {
          value: "phone",
          label: this.$t("phone"),
        },
        {
          value: "email",
          label: this.$t("email"),
        },
      ],

      newReservationModalForm: {
        // TODO newReservationModalForm
        reservationType: "normal",
        reservationCode: "",
        userID: undefined,
        selectedCurrency: {
          text: "",
          icon: "",
          fontAwesomeIcon: "",
          localeCode: "",
          value: "",
        },
        invoices: {
          invoiceType: "individual",
          individual: {
            province: "",
            postCode: "",
            address: "",
          },
          individualCompany: {
            firstname: "",
            lastname: "",
            taxOffice: "",
            taxNumber: "",
            province: "",
            postCode: "",
            address: "",
          },
          company: {
            title: "",
            taxOffice: "",
            taxNumber: "",
            province: "",
            postCode: "",
            address: "",
          },
          abroad: {
            title: "",
            taxNumber: "",
            taxOffice: "",
            country: "",
            province: "",
            postCode: "",
            address: "",
          },
        },
        searchPrice: {
          rules: {
            dailyRangeLimit: 0,
            totalRangeLimit: 0,
          },
          car: {
            image: "default_car.png",
          },
          pricing: {
            provision: 0,
            totalPrice: 0,
            dropPrice: 0,
          },
          vendor: {
            logo: "default_vendor.png",
          },
          office: {
            telephone: "",
            name: "",
            address: "",
            officeSlug: "",
            deliveryType: "",
          },
          returnOffice: {
            telephone: "",
            name: "",
            address: "",
            officeSlug: "",
            deliveryType: "",
          },
        },
        location: {
          start: {
            group: "",
            name: "",
            slug: "",
          },
          end: {
            group: "",
            name: "",
            slug: "",
          },
        },
        datetime: {
          start: "",
          end: "",
        },
        driverInfo: {
          name: "",
          lastname: "",
          phone: "",
          phoneInput: "",
          email: "",
          birthday: "",
          permissions: [],
          identity: {
            value: "",
            certificateType: "tc",
          },
          flightNo: "",
        },
        card: {
          name: "",
          cardNumber: "",
          expiration: "",
          security: "",
        },
        selectedExtras: [], // Seçilen ekstra listesi
        fullProtectionPrice: 0, // Tam Koruma
        extrasTotalPrice: 0, // Ekstraların toplam Fiyatı
        payableOnDelivery: 0, // Teslim alırken ödenecek tutar
        country: "",
        sendMailToDriver: this.$store.state.GetServerData.partnerID
          ? this.$store.state.GetServerData?.sendMailToDriver || "noPrice"
          : this.selectedPartnerInfo
          ? this.selectedPartnerInfo.sendMailToDriver || "noPrice"
          : "withPrice",
        sendSmsToDriver: this.$store.state.GetServerData.partnerID
          ? this.$store.state.GetServerData?.sendSmsToDriver || "send"
          : this.selectedPartnerInfo
          ? this.selectedPartnerInfo.sendSmsToDriver || "send"
          : "send",
        city: "",
      },
      // KULLANICILAR MODAL
      usersModal: false,
      userPermissionModal: false,
      usersModalTitle: "",
      usersModalSelectedUserOut: {},
      usersModalSelectedUser: {},
      usersModalSelectedUserConfirmCode: "",
      usersModalForm: {
        searchValue: "",
      },
      usersModalColumnDefs: [
        {
          field: "name",
          headerName: this.$t("name"),
          width: 140,
        },
        {
          field: "lastname",
          headerName: this.$t("lastname"),
          width: 140,
        },
        {
          field: "phone",
          headerName: this.$t("phone"),
          width: 140,
        },
        {
          field: "email",
          headerName: this.$t("email"),
          width: 200,
        },
        {
          field: "tcId",
          headerName: "TC",
          width: 200,
        },
        {
          field: "status",
          cellRenderer: (params) => {
            if (params.value === "Active") return "Aktif";
            else if (params.value === "InActive") return this.$t("passive");
            else if (params.value === "Pending") return this.$t("pending");
            else if (params.value === "Banned") return this.$t("blocked");
          },
          cellStyle: (params) => {
            if (params.value === "Active")
              return {
                color: "#2eb85c",
                fontWeight: "bold",
              };
            else if (params.value === "InActive")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
            else if (params.value === "Pending")
              return {
                color: "#f9b115",
                fontWeight: "bold",
              };
            else if (params.value === "Banned")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
          },
          headerName: this.$t("status"),
          width: 120,
        },
      ],
      usersModalDefaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      usersModalRowData: [],
      usersModalRowSelection: "multiple", // Satır Seçim Türü.
      usersModalStatusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      // SÜRÜCÜLER MODAL
      driversModal: false,
      driversModalTitle: "",
      driversModalColumnDefs: [
        {
          field: "name",
          headerName: this.$t("name"),
          width: 100,
        },
        {
          field: "lastname",
          headerName: this.$t("lastname"),
          width: 100,
        },
        {
          field: "phone",
          headerName: this.$t("phone"),
          width: 130,
        },
        {
          field: "email",
          headerName: this.$t("email"),
          width: 170,
        },
        {
          field: "birthday",
          cellRenderer: (params) => {
            return moment(new Date(params.data.birthday)).format("DD.MM.YYYY");
          },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return params.data.birthday;
          },
          headerName: this.$t("date_of_birth"),
          width: 120,
        },
        {
          field: "identity",
          cellRenderer: (params) => {
            return params.data.identity.certificateType === "tc"
              ? this.$t("tc_identity") + " - " + params.data.identity.value
              : this.$t("passport") + " - " + params.data.identity.value;
          },
          valueGetter: (params) => {
            // cellRenderer kullanımında sıralama yapılabilmesi için kullanılacak fonksiyon
            return params.data.identity.value;
          },
          headerName: this.$t("identity_type"),
          width: 160,
        },
        {
          field: "flightNo",
          headerName: this.$t("flight_number"),
          width: 100,
        },
      ],
      driversModalDefaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      driversModalRowData: [],
      driversModalRowSelection: "multiple", // Satır Seçim Türü
      driversModalStatusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
    };
  },
  created() {
    this.locale = this.$store.state.locale || "tr";
    localThis = this;
    this.getLocationList();
    this.setPaymentTypes();
    this.assignFullCredit();

    if (this.$store.state.GetServerData?.role === "PARTNER") {
      this.swapColumn(12, 3);
      this.swapColumn(13, 4);
    }

    this.$route?.query?.searchID && this.bidList(this.$route?.query?.searchID);
  },
  watch: {
    "$i18n.locale": function (val, oldVal) {
      window.location.reload();
    },
    "newReservationModalForm.country": function (value) {
      this.dataDropDown.cities = this.dummy.cities[value.name].map((d) => ({
        label: d,
        value: d,
      }));
    },
    resTypeFilter(newVal, oldVal) {
      this.rowData = this.resData;
      this.rowData = this.rowData.filter((item) => {
        return newVal.every((filterItem) => {
          return item.vendor.reservationTypes[filterItem] === true;
        });
      });
    },
    paymentModalShow: function (val) {
      if (!val && !this.newReservationModal) {
        this.clearNewReservationModal();
      }
    },
    rowData: function (val) {
      if (val.length == 0 && this.rowEmpty) {
        Vue.swal({
          icon: "error",
          title: this.$t("alert.no_records_found"),

          confirmButtonText: this.$t("okey"),
        });
      }
    },

    searchBidSelectedDeliveryLocationSlug: function (val) {
      this.setDeliveryLocation(val);
    },
    manuallySearchBidSelectedDeliveryLocationSlug: function (val) {
      this.setDeliveryLocation(val);
    },
    searchBidSelectedReturnLocationSlug: function (val) {
      this.setReturnLocation(val);
    },
    manuallySearchBidSelectedReturnLocationSlug: function (val) {
      this.setReturnLocation(val);
    },
    newReservationModalFullProtection: function () {
      if (this.newReservationModalFullProtection)
        this.newReservationModalForm.fullProtectionPrice = fullProtectionPrice;
      else this.newReservationModalForm.fullProtectionPrice = 0;

      setTimeout(() => {
        // setTimeout kullandık. Çünkü işlem sonrasında input'u güncellediğimizde yine sıfır değerini alıyordu.
        // Extra kodlarına göre ayrılan inputları döndürüp değerlerini güncelliyoruz.
        this.newReservationModalForm.selectedExtras.forEach((e) => {
          document.querySelector("#extra-" + e.code).value = e.count;
        });
      }, 10);
    },
    newReservationModal: function (val, oldVal) {
      if (val) {
        this.additionalExtrasData();
      } else {
        !["noneSecurePos", "debit"].includes(this.paymentType) &&
          this.clearNewReservationModal();
      }
      this.paymentType = "";
      this.openSelectLink = false;
    },
    totalExtras: function (value) {
      this.setLoadingPrice();
    },
    "newReservationModalForm.reservationType": function (value) {
      const currency = this.$store.state.currency || "TRY";
      const RETURN_PAYMENT =
        this.newReservationModalForm?.searchPrice?.pricing?.totalPrice[
          currency
        ] +
        this.newReservationModalForm?.searchPrice?.pricing?.dropPrice[
          currency
        ] +
        this.newReservationModalForm?.extrasTotalPrice -
        this.newReservationModalForm?.searchPrice.pricing?.totalPrice[currency];
      const PROVISION =
        this.newReservationModalForm?.searchPrice?.pricing?.provision[currency];
      const TOTAL_PAYMENT = Func.toMoneyFormat(
        this.newReservationModalForm.searchPrice.pricing.totalPrice[currency],
        true
      );
      if (value == "fullCredit") {
        this.nowPaymentPrice =
          TOTAL_PAYMENT + Func.toMoneyFormat(RETURN_PAYMENT);
        this.totalPaymentPrice = this.nowPaymentPrice;
        this.returnPaymentPrice = 0;
        this.provisionPrice = 0;
      } else if (value == "normal") {
      }
      this.setLoadingPrice();
    },
    selectedPartnerInfo: function (value) {
      if (this.selectedPartnerInfo) {
        this.partnerID = this.selectedPartnerInfo._id;
        this.newReservationModalForm.sendMailToDriver =
          this.selectedPartnerInfo.sendMailToDriver || "noPrice";

        this.newReservationModalForm.sendSmsToDriver =
          this.selectedPartnerInfo.sendSmsToDriver || "send";
      }
    },
    partnerID: function (value) {
      value
        ? (this.newReservationModalForm.partnerID = value)
        : (this.newReservationModalForm.partnerID = null);
    },
    isManually(val) {
      val && this.getVendor() && this.getCar();
    },
    manuallySearchBidSelectedVendor(val) {
      this.getVendorLocation(val);
      this.manuallySearchBidForm.vendor_apiSlug = val;
      var selectedVendor = this.manuallySearchBidSelectVendorOptions.find(
        (item) => item.value === val
      );
      if (selectedVendor) {
        this.manuallySearchBidForm.vendor_commision_daily =
          selectedVendor.commissionRateDaily;
        this.manuallySearchBidForm.vendor_commision_monthly =
          selectedVendor.commissionRateMonthly;
      }
    },
    openSelectLink(val) {
      if (!val) {
        this.linkSendType = null;
      }
    },
  },
  asyncComputed: {
    async getPartnerInfo() {
      if (
        this.$store.state.GetServerData.signularRules.includes(
          "PARTNER_RESERVATION_CREATE"
        )
      ) {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/partner"
        );
        this.partnerInfo = data.map((item) => {
          return {
            ...item,
            selectedPartnerValue: {
              title: item.brand || item.title,
              _id: item._id,
              sendSmsToDriver: item.sendSmsToDriver || "send",
              sendMailToDriver: item.sendMailToDriver || "noPrice",
            },
          };
        });

        // ? sort selectedPartnerValue by title asc
        this.partnerInfo.sort((a, b) =>
          a.selectedPartnerValue.title.localeCompare(
            b.selectedPartnerValue.title
          )
        );
      }
    },

    async isPartnerPromo() {
      let code = await axios.get(
        process.env.VUE_APP_API_URL + "admin/settings"
      );
      this.partnerPromoCodeStatus = code.data?.settings?.partnerPromoCodeStatus;
    },
    async getCountriesAndCity() {
      try {
        let { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}dummy-countries-city`
        );
        this.dummy.countries = data.map((d) => ({
          name: d.country,
          value: d.country,
        }));
        let citiesData = {};
        data.forEach((d) => {
          citiesData[`${d.country}`] = d.cities;
        });
        this.dummy.cities = citiesData;
      } catch (error) {}
    },
  },
  computed: {
    totalExtras: function () {
      if (this.$store.state.currency == "TRY") {
        return _.sumBy(
          this.newReservationModalForm.selectedExtras,
          "totalPrice.TRY"
        );
      } else if (this.$store.state.currency == "EUR") {
        return _.sumBy(
          this.newReservationModalForm.selectedExtras,
          "totalPrice.EUR"
        );
      } else if (this.$store.state.currency == "USD") {
        return _.sumBy(
          this.newReservationModalForm.selectedExtras,
          "totalPrice.USD"
        );
      } else {
        return "";
      }
    },

    additionalExtras: function () {
      var deger = [];

      deger = _.filter(this.additionalExtrasItems, (product) => {
        _.forEach(this.newReservationModalForm.selectedExtras, (extra) => {
          if (extra.code === product.code) {
            product.status = true;
          }
        });
        return product;
      });

      return deger;
    },
    isPartner() {
      return (
        this.$store.state.GetServerData?.role == "PARTNER" || this.partnerID
      );
    },
  },
  methods: {
    pdfExport() {
      const printParams = {
        PDF_HEADER_COLOR: "#f8f8f8",
        PDF_INNER_BORDER_COLOR: "#dde2eb",
        PDF_OUTER_BORDER_COLOR: "#babfc7",
        PDF_LOGO:
          "https://raw.githubusercontent.com/AhmedAGadir/ag-grid-todo-list-react-typescript/master/src/assets/new-ag-grid-logo.png",
        PDF_PAGE_ORITENTATION: "landscape",
        PDF_WITH_HEADER_IMAGE: false,
        PDF_WITH_FOOTER_PAGE_COUNT: true,
        PDF_HEADER_HEIGHT: 25,
        PDF_ROW_HEIGHT: 15,
        PDF_ODD_BKG_COLOR: "#fcfcfc",
        PDF_EVEN_BKG_COLOR: "#ffffff",
        PDF_WITH_CELL_FORMATTING: true,
        PDF_WITH_COLUMNS_AS_LINKS: false,
        PDF_SELECTED_ROWS_ONLY: false,
      };
      printDoc(printParams, this.gridApi, this.gridColumnApi);
    },
    clickAggreInfoManipulate() {
      this.manipulateInfoModal = false;
      this.createReservation(true);
    },
    async setLoadingPrice() {
      const calculatedPricesData = {
        currency: this.$store.state.currency,
        reservationType: this.newReservationModalForm.reservationType,
        paymentType: this.paymentType,
        selectedExtras: this.newReservationModalForm.selectedExtras,
        newDailyPrice:
          this.calculatedPrices?.dailyRentalPrice &&
          this.calculatedPrices?.dailyRentalPrice[this.$store.state.currency],
      };
      const calculatedPricesResponse = await axios.post(
        process.env.VUE_APP_API_URL +
          "reservation/calculate/" +
          this.newReservationModalForm.searchPrice._id,
        calculatedPricesData,
        {
          withCredentials: true,
        }
      );
      this.calculatedPrices = calculatedPricesResponse.data.data;
      this.editingDailyPrice = false;
    },
    async userSendOffer() {
      const apiUrl = process.env.VUE_APP_API_URL;
      const vendor = await axios.get(apiUrl + "vendor", {
        withCredentials: true,
      });

      var params = {
        userInfo: {
          name: "",
          lastname: "",
          email: "",
          telephone: "",
          lang: "",
        },
        vendorInfo: vendor,
        carInfo: {
          data: {},
        },
        locationInfo: this.$store.state.searchForm,
      };
      const sendOffer = await axios.post(
        apiUrl + "user/email/cardetail",
        params,
        {
          withCredentials: true,
        }
      );
    },
    showReturnLocation() {
      this.isDifferentReturnLocation = !this.isDifferentReturnLocation;
    },
    assignFullCredit() {
      const columnDefs = this.columnDefs;
    },
    onInput(formattedNumber, { number, valid, country }) {
      if (valid === true) {
        this.telefondogrumu = true;
        this.newReservationModalForm.driverInfo.phone = number;
      } else {
        this.telefondogrumu = false;
      }
    },
    setPaymentTypes() {
      const availablePaymentTypes =
        this.$store.state.GetServerData.typesOfPaymentAvailable;
      const userType = this.$store.state.GetServerData.userType;

      if (userType == "admin") {
        this.typesOfPaymentAvailableOptions = this.defaultPaymentTypes;
      } else {
        this.typesOfPaymentAvailableOptions = availablePaymentTypes.map(
          (type) => {
            return {
              label: this.defaultPaymentTypes.find(
                (defaultPayType) => defaultPayType.value == type
              ).label,
              value: type,
            };
          }
        );
      }
    },
    paymentModalBackButton() {
      this.paymentModalShow = false;
      this.newReservationModal = true;
    },
    async additionalExtrasData() {
      this.extrasLoading = true;
      await axios
        .get(process.env.VUE_APP_API_URL + "search/price", {
          params: {
            _id: this.newReservationModalForm.searchPrice._id,
          },
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.boardingPassRequired =
              response.data.searchPrice.office.boardingPass || false;
            this.additionalExtrasItems =
              response.data.searchPrice.additionalProducts;
          }
        });
      this.extrasLoading = false;
    },
    onGridReady(params) {
      // Grid ready olduğunda

      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var columnState = JSON.parse(localStorage.getItem("gridReservationsNew"));
      if (columnState) {
        params.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
    },
    onColumnMoved(params) {
      var columnState = JSON.stringify(params.columnApi.getColumnState());
      localStorage.setItem("gridReservationsNew", columnState);
    },
    updatePermissionsCheckBox(value) {
      if (
        this.newReservationModalForm.driverInfo.permissions.some(
          (item) => item === value
        )
      ) {
        let index =
          this.newReservationModalForm.driverInfo.permissions.findIndex(
            (item) => item === value
          );

        if (index !== -1) {
          this.newReservationModalForm.driverInfo.permissions =
            this.newReservationModalForm.driverInfo.permissions.filter(
              function (ele) {
                return ele != value;
              }
            );
        }
      } else this.newReservationModalForm.driverInfo.permissions.push(value);
    },
    async onRowDoubleClicked(params) {
      this.newReservationModalForm.searchPrice = params.node.data;

      this.reservationTypes = [
        {
          value: "normal",
          label: "Normal",
        },
        {
          value: "fullCredit",
          label: "Full Credit",
        },
      ];

      // prepare Reservation Types
      this.reservationTypes = [];
      this.calculatedPrices = {};
      const availableReservationTypes =
        this.$store.state.GetServerData.typesOfReservationAvailable;
      const userType = this.$store.state.GetServerData.userType;
      Object.entries(
        this.newReservationModalForm.searchPrice.vendor.reservationTypes
      ).filter(([key, value]) => {
        value == true &&
          (availableReservationTypes?.[key] == true || userType == "admin") &&
          this.reservationTypes.push({
            value: key,
            label: this.$t(key),
          });
      });

      if (params.data.isManuel) {
        this.newReservationModalForm.datetime.start =
          this.manuallySearchBidForm.delivery.datetime;
      } else {
        this.newReservationModalForm.datetime.start =
          this.searchBidForm.delivery.datetime;
      }
      this.newReservationModalForm.datetime.end =
        this.searchBidForm.return.datetime;
      new Date(
        new Date().setFullYear(
          new Date().getFullYear() -
            this.newReservationModalForm.searchPrice.rules.driverAge
        )
      );
      this.setLoadingPrice();
      this.editingDailyPrice = false;
      this.newReservationModal = true;
    },
    getUserModalGridContextMenuItems(params) {
      var _this = this;
      var result = [
        {
          name: this.$t("select_user"),
          action: function () {
            _this.selectUser(params);
          },
        },
        {
          name: this.$t("copy"),
          action: function () {
            _this.gridApi.copySelectedRangeToClipboard();
          },
        },
      ];

      return result;
    },
    getDriverModalGridContextMenuItems(params) {
      var _this = this;
      var result = [
        {
          name: this.$t("select_driver"),
          action: function () {
            _this.selectDriver(params);
          },
        },
        {
          name: this.$t("copy"),
          action: function () {
            _this.gridApi.copySelectedRangeToClipboard();
          },
        },
      ];

      return result;
    },
    onRowDoubleClickedDriverModal(params) {
      this.selectDriver(params);
    },
    onRowDoubleClickedUserModal(params) {
      this.selectUser(params);
    },
    getLocationList() {
      var _this = this;

      this.$Progress.start();

      axios
        .get(
          process.env.VUE_APP_API_URL +
            "admin/location?status=true&forBookingForm=true",
          {
            headers: {
              lang: this.$store.state.locale || "tr",
            },
          }
        )
        .then((response) => {
          this.$Progress.finish();

          if (response.data.result === "success") {
            response.data.locationList.forEach((e) => {
              _this.searchBidLocationList.push({
                group: e.city,
                name: e.locationName,
                slug: e.slug,
              });

              _this.searchBidSelectLocationOptions.push({
                label: e.locationName,
                value: e.slug,
              });
            });

            // Sayfa ilk yüklenmede birinci dataları set ettik.
            this.searchBidForm.delivery.group =
              response.data.locationList[0].city;
            this.searchBidForm.delivery.name =
              response.data.locationList[0].locationName;
            this.searchBidForm.delivery.slug =
              response.data.locationList[0].slug;
            this.searchBidForm.return.group =
              response.data.locationList[0].city;
            this.searchBidForm.return.name =
              response.data.locationList[0].locationName;
            this.searchBidForm.return.slug = response.data.locationList[0].slug;

            // Reservasyon Modal'a da location datasını set ettik.
            this.newReservationModalForm.location.start.group =
              response.data.locationList[0].city;
            this.newReservationModalForm.location.start.name =
              response.data.locationList[0].locationName;
            this.newReservationModalForm.location.start.slug =
              response.data.locationList[0].slug;
            this.newReservationModalForm.location.end.group =
              response.data.locationList[0].city;
            this.newReservationModalForm.location.end.name =
              response.data.locationList[0].locationName;
            this.newReservationModalForm.location.end.slug =
              response.data.locationList[0].slug;
          }
        });
    },
    setDeliveryLocation() {
      var selectedLocationSlug = this.isManually
        ? this.manuallySearchBidSelectedDeliveryLocationSlug
        : this.searchBidSelectedDeliveryLocationSlug;
      let location = this.searchBidLocationList.filter((e) => {
        return e.slug === selectedLocationSlug;
      });
      if (this.isManually) {
        this.manuallySearchBidForm.delivery.group = location[0].group;
        this.manuallySearchBidForm.delivery.name = location[0].name;
        this.manuallySearchBidForm.delivery.slug = location[0].slug;
        this.setReturnLocation(
          this.manuallySearchBidSelectedDeliveryLocationSlug
        );
      } else {
        this.searchBidForm.delivery.group = location[0].group;
        this.searchBidForm.delivery.name = location[0].name;
        this.searchBidForm.delivery.slug = location[0].slug;

        this.newReservationModalForm.location.start.group = location[0].group;
        this.newReservationModalForm.location.start.name = location[0].name;
        this.newReservationModalForm.location.start.slug = location[0].slug;
        this.setReturnLocation(this.searchBidSelectedDeliveryLocationSlug);
      }
    },
    setReturnLocation(val) {
      let location;
      //erdem abi sor
      //       if (val) {
      //   if (this.isManually) {
      //     this.manuallySearchBidSelectedReturnLocationSlug = val;
      //   } else {
      //     this.searchBidSelectedReturnLocationSlug = val;
      //   }
      // }

      // const targetSlug = this.isManually
      //   ? this.manuallySearchBidSelectedReturnLocationSlug
      //   : this.searchBidSelectedReturnLocationSlug;

      // const location = this.searchBidLocationList.filter((e) => e.slug === targetSlug);

      if (this.isManually) {
        if (val) {
          this.manuallySearchBidSelectedReturnLocationSlug = val;
          location = this.searchBidLocationList.filter((e) => {
            var deger = e.slug === val;

            return deger;
          });
        } else {
          location = this.searchBidLocationList.filter((e) => {
            var deger =
              e.slug === this.manuallySearchBidSelectedReturnLocationSlug;
            return deger;
          });
        }
        this.manuallySearchBidForm.return.group = location[0].group;
        this.manuallySearchBidForm.return.name = location[0].name;
        this.manuallySearchBidForm.return.slug = location[0].slug;
      } else {
        if (val) {
          this.searchBidSelectedReturnLocationSlug = val;
          location = this.searchBidLocationList.filter((e) => {
            var deger = e.slug === val;

            return deger;
          });
        } else {
          location = this.searchBidLocationList.filter((e) => {
            var deger = e.slug === this.searchBidSelectedReturnLocationSlug;
            return deger;
          });
        }

        this.searchBidForm.return.group = location[0].group;
        this.searchBidForm.return.name = location[0].name;
        this.searchBidForm.return.slug = location[0].slug;
      }

      this.searchBidForm.return.group = location[0].group;
      this.searchBidForm.return.name = location[0].name;
      this.searchBidForm.return.slug = location[0].slug;

      this.newReservationModalForm.location.end.group = location[0].group;
      this.newReservationModalForm.location.end.name = location[0].name;
      this.newReservationModalForm.location.end.slug = location[0].slug;
    },
    searchBid() {
      if (
        !this.searchBidSelectedDeliveryLocationSlug &&
        (!this.isManually ||
          !this.manuallySearchBidSelectedDeliveryLocationSlug)
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_location"),
          html: this.$t("alert.select_pickup"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }

      if (
        !this.searchBidSelectedReturnLocationSlug &&
        (!this.isManually || !this.manuallySearchBidSelectedReturnLocationSlug)
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("return_location"),
          html: this.$t("alert.select_return"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }

      // Alış tarihi seçilmemiş ise
      if (
        this.searchBidForm.delivery.datetime === null &&
        (!this.isManually ||
          !this.manuallySearchBidForm.delivery.datetime === null)
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_time"),
          html: this.$t("alert.select_pickup_time"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }

      // İade tarihi seçilmemiş ise
      if (
        this.searchBidForm.return.datetime === null &&
        (!this.isManually ||
          !this.manuallySearchBidForm.return.datetime === null)
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("return_time"),
          html: this.$t("alert.select_return_time"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (
        this.isManually &&
        this.manuallySearchBidForm.price.vendorDailyPrice == 0
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("vendor_daily_price"),
          html: this.$t("alert.vendor_daily_price"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (
        this.isManually &&
        this.manuallySearchBidForm.price.rentalDailyPrice == 0
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("rental_daily_price"),
          html: this.$t("alert.rental_daily_price"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (
        this.isManually &&
        this.manuallySearchBidForm.car.deliveryType == ""
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("delivery_type"),
          html: this.$t("alert.delivery_type"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (this.isManually && this.manuallySearchBidForm.car.fuelPolicy == "") {
        Vue.swal({
          icon: "error",
          title: this.$t("fuel_policy"),
          html: this.$t("alert.fuel_policy"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (this.isManually && this.manuallySearchBidForm.rules.driverAge < 18) {
        Vue.swal({
          icon: "error",
          title: this.$t("driver_age"),
          html: this.$t("alert.driver_age"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (
        this.isManually &&
        this.manuallySearchBidForm.rules.dailyRangeLimit == 0 &&
        this.manuallySearchBidForm.rules.totalRangeLimit == 0
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("daily_range_limit"),
          html: this.$t("alert.daily_range_limit_min_max"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (this.isManually && this.manuallySearchBidForm.car.car_id == "") {
        Vue.swal({
          icon: "error",
          title: this.$t("car_id"),
          html: this.$t("alert.car_id"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }

      // Alış tarihi hesaplanan minimum alış tarihinden küçük ise
      if (
        new Date(this.searchBidForm.delivery.datetime).getTime() <
        new Date(deliveryMinDateTime).getTime()
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_time"),
          html: this.$t("alert.select_pickup_date_warning", {
            date: moment(deliveryMinDateTime).format("DD.MM.YYYY HH:mm"),
          }),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      // Manuel Rezervasyon Alış tarihi hesaplanan minimum alış tarihinden küçük ise
      if (
        new Date(this.manuallySearchBidForm.delivery.datetime).getTime() <
        new Date(deliveryMinDateTime).getTime()
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_time"),
          html: this.$t("alert.select_pickup_date_warning", {
            date: moment(deliveryMinDateTime).format("DD.MM.YYYY HH:mm"),
          }),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }

      // Alış tarihi hesaplanan maksimum alış tarihinden büyük ise
      if (
        new Date(this.searchBidForm.delivery.datetime).getTime() >
        new Date(deliveryMaxDateTime).getTime()
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_time"),
          html: this.$t("alert.select_pickup_date_warning2", {
            date: moment(deliveryMaxDateTime).format("DD.MM.YYYY HH:mm"),
          }),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      //  Manuel Rezervasyon Alış tarihi hesaplanan maksimum alış tarihinden büyük ise
      if (
        new Date(this.manuallySearchBidForm.delivery.datetime).getTime() >
        new Date(deliveryMaxDateTime).getTime()
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_time"),
          html: this.$t("alert.select_pickup_date_warning2", {
            date: moment(deliveryMaxDateTime).format("DD.MM.YYYY HH:mm"),
          }),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }

      if (
        new Date(this.searchBidForm.delivery.datetime).getTime() >=
        new Date(this.searchBidForm.return.datetime).getTime()
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_time"),
          html: this.$t("alert.select_pickup_date_warning3"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }
      if (
        new Date(this.manuallySearchBidForm.delivery.datetime).getTime() >=
        new Date(this.manuallySearchBidForm.return.datetime).getTime()
      ) {
        Vue.swal({
          icon: "error",
          title: this.$t("pickup_time"),
          html: this.$t("alert.select_pickup_date_warning3"),
          confirmButtonText: this.$t("okey"),
        });

        return;
      }

      this.$Progress.start();
      this.rowEmpty = false;
      this.rowData = [];

      this.searchBidInputDisabled = true; // Arama tuşunu disable ettik
      this.$store.state.isLoading = true;

      const params = {
        promo_code: this.promo_code,
        partnerAPI: this.isPartner
          ? this.$store?.state?.GetServerData?._id
          : null,
        pickup_location: this.searchBidForm.delivery.slug,
        return_location: this.searchBidForm.return.slug,
        pickup_datetime:
          moment(this.searchBidForm.delivery.datetime).format("YYYY-MM-DD") +
          "T" +
          moment(this.searchBidForm.delivery.datetime).format("HH:mm") +
          ":00Z",
        return_datetime:
          moment(this.searchBidForm.return.datetime).format("YYYY-MM-DD") +
          "T" +
          moment(this.searchBidForm.return.datetime).format("HH:mm") +
          ":00Z",
      };
      const manuallyParams = {
        pickup_location: this.manuallySearchBidForm.delivery.slug,
        return_location: this.manuallySearchBidForm.return.slug,
        pickup_datetime:
          moment(this.manuallySearchBidForm.delivery.datetime).format(
            "YYYY-MM-DD"
          ) +
          "T" +
          moment(this.manuallySearchBidForm.delivery.datetime).format("HH:mm") +
          ":00Z",
        return_datetime:
          moment(this.manuallySearchBidForm.return.datetime).format(
            "YYYY-MM-DD"
          ) +
          "T" +
          moment(this.manuallySearchBidForm.return.datetime).format("HH:mm") +
          ":00Z",
        manuallyReservatioınInfo: this.manuallySearchBidForm,
        isManually: this.isManually,
      };

      const post = {
        url: process.env.VUE_APP_API_URL + "search",
        method: "post",
        data: this.isManually ? manuallyParams : params,
      };

      if (this.partnerID) {
        post.headers = {
          partnerID: this.partnerID,
        };
      }
      axios(post).then(async (response) => {
        if (response.data.result === "success") {
          this.bidList(response.data.searchData._id);
          this.getSelectableCampaigns();
          this.$router.replace({
            path: this.$route.path,
            query: { searchID: response.data.searchData._id },
          });
          this.isManually = false;
        } else {
          this.searchBidInputDisabled = false; // Arama tuşunu enable ettik
          let message = "";
          switch (response.data.message) {
            case "INVALID_PICKUP_DATE":
              message = this.$t("INVALID_PICKUP_DATE");
              break;
            case "INVALID_DELIVERY_DATE":
              message = this.$t("INVALID_DELIVERY_DATE");
              break;
            case "INVALID_PROMO_CODE":
              message = this.$t("INVALID_PROMO_CODE");
              break;
            case "ARAMA_TALEBI_OLUSTURULMADI":
              message = this.$t("ARAMA_TALEBI_OLUSTURULMADI");
              break;
            case "PARTNER_CANT_USE":
              message = this.$t("PARTNER_CANT_USE");
              break;
            case "INVALID_PROMO_DATES":
              message = this.$t("INVALID_PROMO_DATES");
              break;
            default:
              message = this.$t("DEFAULT");
              break;
          }
          this.$store.state.isLoading = false;
          Vue.swal({
            icon: "error",
            title: this.$t("find_a_car"),
            html: message,
            confirmButtonText: this.$t("okey"),
          });
        }
      });
    },
    async bidList(searchID) {
      const params = {
        page: 0,
        searchID: searchID,
        searchFilter: {},
        selectedCurrency: this.$store.state.currency,
        asyncSearch: true,
      };

      // Sonuç success olana kadar sonsuz döngüye girdik.
      let searchResult = await axios.get(
        process.env.VUE_APP_API_URL + "search",
        {
          params: params,
        }
      ); // Hazırlanan parametreye göre data çekiyoruz.

      if (searchResult.data.result === "success") {
        // Sonuç success ise

        this.$Progress.finish();
        this.searchBidInputDisabled = false; // Arama tuşunu enable ettik
        this.$store.state.isLoading = false;

        if (searchResult.data.searchDetail) {
          // searchDetail datası var ise (undefined değil ise)
          this.rowData = searchResult.data.searchDetail.dataList;
          this.resData = searchResult.data.searchDetail.dataList;
          // this.searchBidCollapsed = false;
          this.bidDetailGridShow = true;
          this.searchBidCollapsed = !this.searchBidCollapsed;
        } else {
          this.rowEmpty = true;
          this.rowData = [];
        }
      }
      /* else if(searchResult.data.result === 'warning') console.info(searchResult.data.message);
                                        else console.error(searchResult.data.message);*/
    },
    async getSelectableCampaigns() {
      try {
        const pickupDateTime = this.$moment(
          this.searchBidForm.delivery.datetime
        ).format("YYYY-MM-DDTHH:mm:ss[Z]");
        const dropoffDateTime = this.$moment(
          this.searchBidForm.return.datetime
        ).format("YYYY-MM-DDTHH:mm:ss[Z]");

        const response = await axios.get(
          process.env.VUE_APP_API_URL + "admin/campaign",
          {
            params: {
              selectable: true,
              pickup_date: pickupDateTime,
              dropoff_date: dropoffDateTime,
              pickup_location: this.searchBidForm.delivery.slug,
            },
          }
        );
        if (response) {
          this.selectableCampaigns = response.data;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    setExtra(name, price, total, count, vendor, code) {
      let _this = this;
      let index = ""; // Ekstra liste içerisinde var ise sıra numarası alınıp eşitleniyor sonraki işlemler bunun üzerinden yürütülüyor

      // Ekstra liste içerisinde varmı kontrol ediliyor (daha önce seçilmişmi)
      let check = this.newReservationModalForm.selectedExtras.filter(function (
        extra
      ) {
        if (extra.name == name) {
          index = _this.newReservationModalForm.selectedExtras.indexOf(extra);
          return extra;
        }
      });

      if (count > 0) {
        // Adet kontrol ediliyor (ekstra seçildiyse)
        if (check.length == 1) {
          // Seçilen ekstra liste içerisinde var ise fiyat üzerinde herhangi bir hata olmaması için liste içerisinden silinip tekrar ekleniyor
          let update = {
            name: this.newReservationModalForm.selectedExtras[index].name,
            price: this.newReservationModalForm.selectedExtras[index].price,
            count: count,
            totalPrice:
              this.newReservationModalForm.selectedExtras[index].total,
            vendor: this.newReservationModalForm.selectedExtras[index].vendor,
            code: this.newReservationModalForm.selectedExtras[index].code,
          };

          this.newReservationModalForm.selectedExtras.splice(index, 1);
          this.newReservationModalForm.selectedExtras.push(update);
        } else {
          // Seçilen ekstra liste içerisinde yok ise listeye ekleniyor

          this.newReservationModalForm.selectedExtras.push({
            name: name,
            price: price,
            count: count,
            totalPrice: total,
            vendor: vendor,
            code: code,
          });
        }
      } else {
        // Seçilen ekstranın adeti 0 ise listeden çıkarılır
        if (check.length == 1) {
          _.find(this.additionalExtras, (extra) => {
            if (extra.code === code) {
              delete extra.status;
            }
          });
          this.newReservationModalForm.selectedExtras.splice(index, 1);
        }
      }

      let extrasTotal = 0;

      if (this.newReservationModalForm.selectedExtras.length > 0) {
        // Ekstraların toplam fiyatı bulunuyor
        this.newReservationModalForm.selectedExtras.forEach((val) => {
          if (this.$store.state.currency == "TRY") {
            extrasTotal += val.totalPrice.TRY;
          }
          if (this.$store.state.currency == "EUR") {
            extrasTotal += val.totalPrice.EUR;
          }
          if (this.$store.state.currency == "USD") {
            extrasTotal += val.totalPrice.USD;
          }
        });
      }

      this.newReservationModalForm.extrasTotalPrice = extrasTotal;

      // Sadece ekstraların aracı alırken ödenecek olması sebebi ile gereksizdir.
      // Fakat ileriki zamanlarda ekstra haricinde ofiste ödsenecek tutar olması halinde şeffaflık ve anlaşılır olması açısından eklendi.
      this.newReservationModalForm.payableOnDelivery = extrasTotal;
    },
    searchUser() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/users/", {
          params: {
            search: this.$store.state.GetServerData?.role != "PARTNER",
            value: this.usersModalForm.searchValue,
            partnerID:
              this.$store.state.GetServerData?.role == "PARTNER" &&
              this.$store.state.GetServerData?.partnerID,
          },
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.usersModalTitle =
              this.$t("users") +
              " - " +
              this.$t("wanted") +
              " :" +
              (this.usersModalForm.searchValue === ""
                ? "-"
                : this.usersModalForm.searchValue);
            this.usersModalRowData = response.data.userList;
            this.usersModal = true;
            this.userPermissionModal = true;
          } else {
            Vue.swal({
              icon: "error",
              title: this.$t("users"),
              html: this.$t("not_found_user"),
              confirmButtonText: this.$t("okey"),
            });
          }
        })
        .catch((error) => {});
    },
    selectUser(params) {
      // TODO selectUser.
      let userInfo = params.node.data;

      const birthday = new Date(
        userInfo.birthday || this.newReservationModalForm.driverInfo.birthday
      );

      var dd = String(birthday.getDate()).padStart(2, "0");
      var mm = String(birthday.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = birthday.getFullYear();

      if (userInfo.name)
        this.newReservationModalForm.driverInfo.name = userInfo.name;
      if (userInfo.lastname)
        this.newReservationModalForm.driverInfo.lastname = userInfo.lastname;
      if (userInfo.phone)
        this.newReservationModalForm.driverInfo.phone = userInfo.phone;
      this.newReservationModalForm.driverInfo.phoneInput = userInfo.phone;
      if (userInfo.email)
        this.newReservationModalForm.driverInfo.email = userInfo.email;
      if (userInfo.birthday)
        this.newReservationModalForm.driverInfo.birthday =
          dd + "" + mm + "" + yyyy;
      if (userInfo.flightNo)
        this.newReservationModalForm.driverInfo.flightNo = userInfo.flightNo;
      if (userInfo.permissions)
        this.newReservationModalForm.driverInfo.permissions =
          userInfo.permissions;

      if (userInfo.defaultIdType == 0) {
        this.newReservationModalForm.driverInfo.identity.value = userInfo.tcId;
        this.newReservationModalForm.driverInfo.identity.certificateType = "tc";
      } else {
        this.newReservationModalForm.driverInfo.identity.value =
          userInfo.passportId;
        this.newReservationModalForm.driverInfo.identity.certificateType =
          "passport";
      }
      if (userInfo.invoices) {
        this.newReservationModalForm.invoices = userInfo.invoices;
      }

      this.newReservationModalForm;

      this.usersModalSelectedUser = userInfo;
      this.usersModalSelectedUserOut = userInfo;

      this.usersModal = false;

      Vue.swal({
        icon: "success",
        title: userInfo.name + " " + userInfo.lastname + this.$t("user_info1"),
        toast: true,
        position: "bottom-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Vue.swal.stopTimer);
          toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
        },
      });
    },
    unselectUser() {
      Vue.swal({
        icon: "question",
        title: this.$t("driver_form"),
        html:
          "<strong>" +
          this.usersModalSelectedUser.name +
          " " +
          this.usersModalSelectedUser.lastname +
          "</strong> " +
          this.$t("delete_driver_form_info"),
        confirmButtonText: this.$t("yes"),
        showCancelButton: true,
        cancelButtonText: this.$t("no"),
      }).then((result) => {
        this.usersModalSelectedUser = {};
        this.userPermissionModal = false;
        // TODO DON
        // this.clearNewReservationModal();

        if (result.isConfirmed) {
          this.newReservationModalForm.driverInfo = {
            name: "",
            lastname: "",
            phone: "",
            email: "",
            birthday: "",

            permissions: [],
            identity: {
              value: "",
              certificateType: "tc",
            },

            flightNo: "",
          };
          this.newReservationModalForm.invoices = {
            invoiceType: "individual",
            individual: {
              province: "",
              postCode: "",
              address: "",
            },
            individualCompany: {
              firstname: "",
              lastname: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            company: {
              title: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            abroad: {
              title: "",
              taxNumber: "",
              taxOffice: "",
              country: "",
              province: "",
              postCode: "",
              address: "",
            },
          };
        }
      });
    },
    getUserDrivers() {
      if (Object.keys(this.usersModalSelectedUser).length === 0) return;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/user-drivers/", {
          params: {
            userID: this.usersModalSelectedUser._id,
          },
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.driversModalTitle =
              this.$t("drivers") +
              " - " +
              this.usersModalSelectedUser.name +
              " " +
              this.usersModalSelectedUser.lastname;
            this.driversModalRowData = response.data.driverList;
            this.driversModal = true;
          } else {
            switch (response.data.errCode) {
              case "USER_NOT_FOUND":
                Vue.swal({
                  icon: "error",
                  title: this.$t("drivers"),
                  html: this.$t("not_found_user"),
                  confirmButtonText: this.$t("okey"),
                });
                break;
              case "DRIVER_NOT_FOUND":
                Vue.swal({
                  icon: "error",
                  title: this.$t("drivers"),
                  html:
                    "<strong>" +
                    this.usersModalSelectedUser.name +
                    " " +
                    this.usersModalSelectedUser.lastname +
                    "</strong> " +
                    this.$t("drivers_not_found"),
                  confirmButtonText: this.$t("okey"),
                });
                break;
            }
          }
        });
    },
    selectDriver(params) {
      this.newReservationModalForm.driverInfo.name = params.node.data.name;
      this.newReservationModalForm.driverInfo.lastname =
        params.node.data.lastname;
      this.newReservationModalForm.driverInfo.phone = params.node.data.phone;
      this.newReservationModalSelectedDriverPhone = params.node.data.phone;
      this.newReservationModalForm.driverInfo.email = params.node.data.email;
      this.newReservationModalForm.driverInfo.birthday =
        params.node.data.birthday;
      this.newReservationModalForm.driverInfo.identity =
        params.node.data.identity;
      this.newReservationModalForm.driverInfo.flightNo =
        params.node.data.flightNo;

      this.driversModal = false;

      Vue.swal({
        icon: "success",
        title:
          params.node.data.name +
          " " +
          params.node.data.lastname +
          this.$t("selected_user"),
        toast: true,
        position: "bottom-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Vue.swal.stopTimer);
          toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
        },
      });
    },
    setPhoneNumber(number, phoneObject) {
      if (number !== "" && number.length > 2) {
        this.newReservationModalSelectedDriverPhone = phoneObject.number;
        this.newReservationModalForm.driverInfo.phone = phoneObject.number;
      } else this.newReservationModalForm.driverInfo.phone = "";
    },
    creditInfoChanged(values) {
      for (const key in values) {
        this.newReservationModalForm.card[key] = values[key];

        switch (key) {
          case "name":
            document.querySelector(
              ".vue-credit-card .credit-card-image .creditcard #Front #svgname"
            ).innerHTML = values[key];
            document.querySelector(
              ".vue-credit-card .credit-card-image .creditcard #Back #svgnameback"
            ).innerHTML = values[key];
            break;
          case "cardNumber":
            document.querySelector(
              ".vue-credit-card .credit-card-image .creditcard #Front #svgnumber"
            ).innerHTML =
              values[key] === "" ? "0123 4567 8910 1112" : values[key];
            break;
          case "expiration":
            document.querySelector(
              ".vue-credit-card .credit-card-image .creditcard #Front #svgexpire"
            ).innerHTML =
              values[key] === ""
                ? "01/" + (new Date().getFullYear() + 5).toString().slice(-2)
                : values[key];
            break;
          case "security":
            document.querySelector(
              ".vue-credit-card .credit-card-image .creditcard #Back #svgsecurity"
            ).innerHTML = values[key];
            break;
        }
      }
    },
    driverIdentityChanged(selectedCertificateType) {
      this.newReservationModalForm.driverInfo.identity.certificateType =
        selectedCertificateType;
    },
    async getVendorLocation(vendorapiSlug) {
      await axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor/locations", {
          params: {
            vendorSlug: vendorapiSlug,
            status: "active",
          },
          withCredentials: true,
        })
        .then(async (response) => {
          if (response.data.result === "success") {
            this.manuallySearchBidSelectLocationOptions = [];
            response.data.vendorLocationList.forEach((e) => {
              this.manuallySearchBidSelectLocationOptions.push({
                label: e.locationDetail.locationName
                  ? e.locationDetail.locationName
                  : e.vendorLocationName,
                value: e.locationDetail.slug,
              });
            });
          }
        });
    },

    async getVendor() {
      await axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor", {
          withCredentials: true,
          params: {
            sort: true,
            status: true,
          },
        })
        .then((response) => {
          if (response.data.result === "success") {
            response.data.vendorList.forEach((e) => {
              this.manuallySearchBidSelectVendorOptions.push({
                commissionRateDaily: e.commissionRateDaily,
                commissionRateMonthly: e.commissionRateMonthly,
                label: e.brand,
                value: e.brandSlug,
              });
            });
          }
        });
    },
    async getCar(fuel, transmission) {
      if (fuel && transmission) {
        await axios
          .get(process.env.VUE_APP_API_URL + "admin/cars", {
            params: {
              status: true,
              fuel: fuel,
              transmission: transmission,
            },
          })
          .then((response) => {
            if (
              response.data.result === "success" &&
              response.data.carList.length > 0
            ) {
              this.manuallySearchBidSelectCarOptions = [];
              response.data.carList.forEach((e) => {
                this.manuallySearchBidSelectCarOptions.push({
                  label: e.brand + " " + e.model,
                  value: e._id,
                });
              });
            }
          });
      }
    },
    async createReservation() {
      if (this.newReservationModalForm.driverInfo.name.trim() === "") {
        Vue.swal({
          icon: "error",
          title: this.$t("driver_info"),
          html: this.$t("warning.driver_empty"),
          confirmButtonText: this.$t("okey"),
        });
        return;
      }
      if (this.paymentType.trim() === "") {
        Vue.swal({
          icon: "error",
          title: this.$t("payment_type"),
          html: this.$t("warning.payment_type_empty"),
          confirmButtonText: this.$t("okey"),
        });
        return;
      }
      if (this.telefondogrumu === false) {
        Vue.swal({
          icon: "error",
          title: this.$t("phone"),
          html: this.$t("phoneFormatErr"),
          confirmButtonText: this.$t("okey"),
        });
        return;
      }
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        var html = "";
        _.forEach(this.$refs.observer.errors, (value, key) => {
          value != ""
            ? (html +=
                "<p><strong>" +
                key +
                this.$t("area") +
                " : </strong>" +
                value +
                "</p>")
            : "";
        });

        Vue.swal({
          icon: "error",
          title: this.$t("check_the_form"),
          html,
          confirmButtonText: this.$t("okey"),
        });
        return;
      }

      this.createReservationBtnStatus = true;
      this.newReservationModalForm.paymentType = this.paymentType;
      // Rezervasyon kullanıcıya tahsis edilmişse, _id bilgisini set ediyoruz.
      if (Object.keys(this.usersModalSelectedUser).length > 0)
        this.newReservationModalForm.userID = this.usersModalSelectedUser._id;

      //set reservation partner creator
      if (this.$store.state.GetServerData?.role == "PARTNER") {
        this.newReservationModalForm.partnerID =
          this.$store.state.GetServerData._id;
      }
      //this.newReservationModalForm.lang = Object.keys(this.usersModalSelectedUser).length > 0 ? this.usersModalSelectedUser.lang : 'tr'; // Seçilen dil
      var oldbirthday = this.newReservationModalForm.driverInfo.birthday;
      if (this.usersModalSelectedUserOut.role === "user") {
        this.newReservationModalForm.driverInfo.birthday =
          this.usersModalSelectedUserOut.birthday;
      } else {
        var birthdaySplit =
          this.newReservationModalForm.driverInfo.birthday.split(".");
        var birthday = new Date(
          Date.UTC(
            birthdaySplit[2],
            birthdaySplit[1] - 1,
            birthdaySplit[0],
            0,
            0,
            0
          )
        );

        this.newReservationModalForm.driverInfo.birthday =
          birthday.toISOString();

        if (
          this.newReservationModalForm.driverInfo.identity.certificateType ===
          "tc"
        ) {
          this.newReservationModalForm.driverInfo.defaultIdType = 0;
          this.newReservationModalForm.driverInfo.tcId =
            this.newReservationModalForm.driverInfo.identity.value;
        } else {
          this.newReservationModalForm.driverInfo.defaultIdType = 1;
          this.newReservationModalForm.driverInfo.passportId =
            this.newReservationModalForm.driverInfo.identity.value;
        }
      }
      delete this.newReservationModalForm.city;
      delete this.newReservationModalForm.country;

      try {
        this.newReservationModalForm.selectedCurrency.text =
          this.$store.state.currency;
        this.newReservationModalForm.selectedCurrency.icon =
          this.currency.symbol;
        this.newReservationModalForm.selectedCurrency.fontAwesomeIcon =
          this.currency.icon;
        this.newReservationModalForm.selectedCurrency.localeCode = "tr-TR";
        this.newReservationModalForm.selectedCurrency.value =
          this.$store.state.currency;

        if (this.openSelectLink && this.linkSendType) {
          this.newReservationModalForm.paymentLinkTypes = this.linkSendType;
        }

        const params = {
          ...this.newReservationModalForm,
          user: this.usersModalSelectedUserOut,
          anotherUser: this.newReservationModalSelectUser,
          currencySelected: this.$store.state.currency,
          newDailyPrice:
            this.calculatedPrices?.dailyRentalPrice &&
            this.calculatedPrices?.dailyRentalPrice[this.$store.state.currency],
        };
        const post = {
          url: process.env.VUE_APP_API_URL + "admin/token-reservation",
          method: "post",
          data: params,
          withCredentials: true,
          headers: {
            lang: this.$store.state.locale || "tr",
          },
        };

        this.partnerID ? (post.headers.partnerid = this.partnerID) : null;
        let response = await axios(post);

        const reservationCode = response.data.reservationCode;
        if (this.paymentType == "noneSecurePos") {
          let routeData = this.$router.resolve({
            name: "Rezervasyon Detayı",
            params: {
              reservationCode,
            },
            query: {
              paymentType: "noneSecurePos",
            },
          });
          window.location.href = routeData.href;
        } else {
          if (reservationCode && reservationCode != undefined) {
            const succesMessage =
              this.newReservationModalForm.paymentLinkTypes === "LinkForSms"
                ? this.$t("reservation_success_sms_info")
                : this.$t("reservation_success_email_info");
            Vue.swal({
              icon: "success",
              title: this.$t("reservation_completed"),
              html:
                "<strong>" + reservationCode + "</strong>  " + succesMessage,
              confirmButtonText: this.$t("go_to_reservation"),
              cancelButtonText: this.$t("close"),
              showCancelButton: true,
              preConfirm: () => {
                let routeData = this.$router.resolve({
                  name: "Rezervasyon Detayı",
                  params: {
                    reservationCode,
                  },
                });

                window.open(routeData.href, "_blank");
              },
            });
          } else {
            Vue.swal({
              icon: "error",
              title: this.$t("reservation"),
              html: response.data.message,
              confirmButtonText: this.$t("okey"),
            });
          }
        }

        this.clearNewReservationModal();

        this.newReservationModal = false;
        this.additionalExtrasItems = [];
        this.createReservationBtnStatus = false;
      } catch (e) {
        this.newReservationModalForm.driverInfo.birthday = oldbirthday;
        this.createReservationBtnStatus = false;
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: this.$t(e.response.data.errors[0].message),
          confirmButtonText: this.$t("okey"),
        });
      } finally {
        this.createReservationBtnStatus = false;
      }
    },
    clearNewReservationModal() {
      this.usersModalSelectedUser = {};
      this.usersModalSelectedUserConfirmCode = "";
      this.totalPaymentPrice = 0;
      this.nowPaymentPrice = 0;
      this.returnPaymentPrice = 0;
      this.usersModalForm.searchValue = "";
      this.newReservationModalFullProtection = false;
      this.newReservationModalSelectUser = false;
      this.newReservationModalSelectedDriverPhone = "";
      this.newReservationModalForm.reservationCode = "";
      this.newReservationModalForm.reservationType = "normal";
      this.newReservationModalForm.userID = undefined;
      this.newReservationModalForm.invoices = {
        invoiceType: "individual",
        individual: {
          province: "",
          postCode: "",
          address: "",
        },
        individualCompany: {
          firstname: "",
          lastname: "",
          taxOffice: "",
          taxNumber: "",
          province: "",
          postCode: "",
          address: "",
        },
        company: {
          title: "",
          taxOffice: "",
          taxNumber: "",
          province: "",
          postCode: "",
          address: "",
        },
        abroad: {
          title: "",
          taxNumber: "",
          taxOffice: "",
          country: "",
          province: "",
          postCode: "",
          address: "",
        },
      };

      this.newReservationModalForm.searchPrice = {
        rules: {},
        car: {
          image: "default_car.png",
        },
        pricing: {
          provision: 0,
          totalPrice: 0,
          dropPrice: 0,
        },
        vendor: {
          logo: "default_vendor.png",
        },
        office: {
          telephone: "",
          name: "",
          address: "",
          officeSlug: "",
          deliveryType: "",
        },
        returnOffice: {
          telephone: "",
          name: "",
          address: "",
          officeSlug: "",
          deliveryType: "",
        },
      };
      this.newReservationModalForm.driverInfo = {
        name: "",
        lastname: "",
        phone: "",
        email: "",
        permissions: [],
        birthday: "",
        identity: {
          value: "",
          certificateType: "tc",
        },
        flightNo: "",
      };
      this.newReservationModalForm.card = {
        name: "",
        cardNumber: "",
        expiration: "",
        security: "",
      };
      this.newReservationModalForm.selectedExtras = []; // Seçilen ekstra listesi
      this.newReservationModalForm.fullProtectionPrice = 0; // Tam Koruma
      this.newReservationModalForm.extrasTotalPrice = 0; // Ekstraların toplam Fiyatı
      this.newReservationModalForm.payableOnDelivery = 0; // Teslim alırken ödenecek tutar
    },
    swapColumn(index1, index2) {
      const temp = this.columnDefs[index1];
      this.$set(this.columnDefs, index1, this.columnDefs[index2]);
      this.$set(this.columnDefs, index2, temp);
    },
  },
};
</script>

<style lang="scss">
.swal2-container {
  z-index: 1000002 !important;
}

.cursor-pointer {
  cursor: pointer !important;

  input {
    &:hover {
      cursor: pointer !important;
    }
  }

  label {
    &:hover {
      cursor: pointer !important;
    }
  }
}

.bil__type {
  label {
    margin-bottom: 0.5rem;
    color: black;
    font-weight: 700 !important;
  }
}

.color-red {
  color: #d65437;
}

.vue-tel-input {
  height: 35px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

.credit-card-form {
  .field {
    label {
      font-size: 0.875rem !important;
      font-weight: bold !important;
      color: #3c4b64;
      padding-bottom: 0 !important;
      margin-bottom: 5px !important;
    }

    input {
      padding: 5px !important;
    }
  }
}

.vue-credit-card .ccicon {
  height: 25px;
  position: absolute;
  right: 6px;
  top: 54%;
  width: auto;
}

.content-fix {
  .input-group {
    .input-group-text {
      margin: 0 !important;
      padding: 0 !important;

      .input-group-btn-left {
        width: 100%;
        height: 100%;
        border: 0;
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        transition: 0.3s;
        box-shadow: unset !important;
        padding: 0 15px;

        &:hover {
          outline: unset;
        }

        &:focus {
          box-shadow: unset !important;
          outline: unset;
        }

        &:active {
          box-shadow: unset !important;
          outline: unset;
        }

        &.nonborder-radius {
          border-radius: 0 !important;
          outline: unset;
        }

        .bg-white {
          background-color: #fff !important;
        }
      }

      .input-group-btn-right {
        width: 100%;
        height: 100%;
        border: 0;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
        transition: 0.3s;
        box-shadow: unset !important;
        padding: 0 15px;

        &:hover {
          outline: unset;
        }

        &:focus {
          box-shadow: unset !important;
          outline: unset;
        }

        &:active {
          box-shadow: unset !important;
          outline: unset;
        }

        &.nonborder-radius {
          border-radius: 0 !important;
          outline: unset;
        }
      }

      .btn-bg-white {
        color: #3c4b64 !important;
        background-color: #fff !important;

        &:hover {
          background-color: #f3f3f3 !important;
        }

        &:active {
          background-color: #e2e2e2 !important;
        }
      }

      .btn-bg-red {
        color: #fff !important;
        background-color: #d65437 !important;

        &:hover {
          background-color: #d65437e5 !important;
        }

        &:active {
          background-color: #c4472c !important;
        }
      }
    }
  }

  p {
    .white {
      color: #fff;
    }
  }
}

.no-padding-modal {
  .modal-body {
    padding: 0 !important;
  }
}

.no-border-modal-aggrid {
  .ag-theme-balham {
    .ag-root-wrapper {
      border: 0 !important;
    }
  }
}

.backdropFix {
  .modal-backdrop {
    z-index: 1060 !important;
  }

  .modal {
    z-index: 1070 !important;
  }
}

.select-user-box {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 15px;
  background-color: #d65337;

  .chk-input {
    input {
      margin-left: 0 !important;
      -webkit-appearance: none;
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid #fff;
      border-radius: 10px;
      vertical-align: sub;
      position: absolute;
      top: 30%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      left: 0;
      outline: none;
      transition: 0.3s;
      box-shadow: unset !important;

      &:checked {
        &:focus,
        &:hover {
          //box-shadow: 0px 0px 10px 0px #000000;
        }

        &:after {
          content: "";
          width: 70%;
          height: 70%;
          position: absolute;
          left: 3px;
          top: 3px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
          background-size: 40px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &:focus,
      &:hover {
        cursor: pointer !important;
        //box-shadow: 0px 0px 10px 10px #000000;
      }
    }

    label {
      display: block;
      margin-left: 10px;
      color: #ffffff;
      font-weight: bold;

      &:hover {
        cursor: pointer !important;
      }
    }
  }

  .selected-user {
    border: 1px solid white;
    border-radius: 5px;

    label {
      margin: 6px 0 6px 10px !important;
    }

    .btn-right {
      float: right;
      padding: 6px 10px;
      color: #3c4b64 !important;
      background-color: #fff !important;

      &:hover {
        cursor: pointer;
        background-color: #f3f3f3 !important;
      }

      &:active {
        background-color: #e2e2e2 !important;
      }
    }
  }

  label {
    color: #fff;
  }

  p {
    color: #fff;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

.chk-box {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 10px;
  background-color: #d65337;
  transition: 0.3s;
  box-shadow: unset !important;

  &:hover {
    cursor: pointer !important;
    background-color: #d65437e5 !important;
  }

  &:active {
    background-color: #c4472c !important;
  }

  input {
    margin-left: 10px !important;
    -webkit-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid #fff;
    border-radius: 10px;
    vertical-align: sub;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    left: 0;
    outline: none;
    transition: 0.3s;
    box-shadow: unset !important;

    &:checked {
      &:focus,
      &:hover {
        //box-shadow: 0px 0px 10px 0px #000000;
      }

      &:after {
        content: "";
        width: 70%;
        height: 70%;
        position: absolute;
        left: 3px;
        top: 3px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
        background-size: 40px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &:focus,
    &:hover {
      cursor: pointer !important;
      //box-shadow: 0px 0px 10px 10px #000000;
    }
  }

  label {
    display: block;
    margin-left: 18px;
    color: #ffffff;
    font-weight: bold;

    &:hover {
      cursor: pointer !important;
    }
  }
}

@media (min-width: 992px) {
  .credit-card-form {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .select-user-box {
    .selected-user {
      .btn-right {
        float: unset;
        text-align: center;
      }
    }
  }
}
//container
.CheckContainer {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
